<template>
    <f-plain-upload
        ref="bb"
        :action="action"
        :fileList="fileList"
        :limit="limit"
        :baifang="baifang"
        :isDetail="isDetail"
        :disabled="disabled"
        @delete="handleDelete"
        @add="handleAdd"
        v-bind="$attrs">
    </f-plain-upload>
</template>

<script>
import { deleteAction } from '@/api/manage'
import FPlainUpload from './FPlainUpload'

export default {
    name: 'FUpload',
    mixins: [],
    components: {
        FPlainUpload
    },
    props: {
        action: {
            type: String,
            default: '/sys/oss/file/upload'
        },
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        baifang: {
            type: Object,
        },
        limit: {
            type: Number,
        },
        // 删除的时候是否要删除文件本身，默认是true，有特殊地方需要用到不删除文件本身，如信息中心-发现管理编辑附件、图片删除
        isDetail: {
          type: Boolean,
          default: true
        },
        disabled: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            fileList: [],
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.fileList = val;
                this.handleYC();
            }
        }
    },
    methods: {
        init() {
            this.fileList = [];
        },
        handleResetFileList() {
            try {
                if (this.fileList.length > 0) {
                    for (let i = 0; i < this.fileList.length; i++) {
                        deleteAction('/oss/file/delete', {id: this.fileList[i]['id']}).then((res) => {
                        })
                    }
                }
            } finally {
                this.fileList = []
                this.handleInput()
            }
        },
        handleAdd(res) {
            let that = this;

                that.fileList.push(res)
                that.handleInput()

        },
        handleDelete(index) {
            this.fileList.splice(index, 1);
            this.handleYC();
            this.handleInput()
        },
      handleYC(){
        if (this.limit){
          if (this.$refs.bb) {
            this.$refs.bb.hideUploadBtn = this.fileList.length>=this.limit;
            console.log('this.$refs.bb.hideUploadBtn',this.$refs.bb.hideUploadBtn,this.fileList,this.limit)
          }
        }
      },
        handleInput() {
            this.$emit('input', this.fileList)
            this.$emit('change', this.fileList)
            this.$emit('getUploadHeight', this.$refs.bb && this.$refs.bb.$el && this.$refs.bb.$el.offsetHeight)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
