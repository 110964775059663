<template>
    <el-menu   
        class="el-menu-vertical-demo"
        :default-active="upRoute || this.$route.path"
        active-text-color="#6a7eb5"
        :collapse="isCollapse"
        unique-opened
        @select="handleSelect"
        router>
        <menu-tree :data="sidebarList" :level="0"></menu-tree>
    </el-menu>
</template>

<script>
    import { mapState } from 'vuex'
    import MenuTree from "./MenuTree"
    export default {
        props: {
            isCollapse: Boolean
        },
        components: { MenuTree },
        computed: {
            ...mapState(['sidebarList','upRoute'])
        },
        methods: {
          handleSelect(key, keyPath) {
            console.log('菜单偶现点击无反应测试：this.sidebarList:', this.sidebarList);
            console.log('菜单偶现点击无反应测试：点击菜单触发:',key, keyPath);
          }
        }
    }
</script>

<style lang="scss" scoped>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 216px;
    }
</style>
