<template>
  <div>
    <el-select
            v-model="objectData[dataKey]"
            clearable filterable class="f-full-width"
            allow-create
            default-first-option
            @blur="productSelect"
            :placeholder="placeholder">
      <el-option
              v-for="item in options"
              :key="item.text"
              :label="item.text"
              :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  /**
   * 可输入选择框，用于数据库字段是一个字符串的情形，该输入为选择+输入模式，提升用户体验
   */
  export default {
    name: "select-stringType",
    props:{
      //数据对象
      objectData:{
        type: Object,
        default: null
      },
      placeholder:{
        type:String,
        default:'请选择或手动输入'
      },
      //数据对象的值作为选中的值的绑定对象的key， objectData[key] 可以取到值
      dataKey:{
        type: String,
        default: null
      },
      //获取选项列表函数
      getOptionsFunc:{
        type: Function
      },
    },
    created() {
      let func = this.getOptionsFunc()
      if( func === undefined){
        throw 'get-options-func返回数据异常'
      }
      func.then(res=>{
        this.options = []
        this.options = res
      })
    },
    activated() {
      let func = this.getOptionsFunc()
      if( func === undefined){
        throw 'get-options-func返回数据异常'
      }
      func.then(res=>{
        this.options = []
        this.options = res
      })
    },
    data(){
      return{
        options:[]
      }
    },
    methods:{
      productSelect(e) {
        let value = e.target.value; // 输入框值
        if(value) { // 你输入才有这个值 不为空，如果你下拉框选择的话 这个值为空
          this.objectData[this.dataKey] = value
          if( this.options.indexOf(value) === -1 )
            this.options.push({label:value,value:value})
        }
      }
    }
  }
</script>

<style scoped>

</style>
