<template>
  <div class="general-table-wrap">
    <el-dropdown
      class="operation"
      trigger="click"
      :hide-on-click="false"
      @command="handleCommand"
      ref="dropdowm"
      v-if="showOperation"
    >
      <span class="el-dropdown-link">
        <el-button size="medium">
          <i class="fa fa-bars"></i>
        </el-button>
      </span>
      <el-dropdown-menu
        slot="dropdown"
        class="table-operater-wrap c-slim-scrollbar"
      >
        <!-- <el-dropdown-item command="SAVE">保存设置</el-dropdown-item> -->
        <el-dropdown-item command="RESTORE">恢复默认设置</el-dropdown-item>
        <el-dropdown-item command="CLEAR">清除所有过滤器</el-dropdown-item>
        <div class="divider">列展示</div>
        <template v-for="(item, index) in display_columns">
          <el-dropdown-item
            v-if="item.type !== 'selection'"
            :key="index"
            :command="index"
          >
            <i
              :class="item.show ? 'el-icon-check' : 'el-icon-close'"
              style="font-weight: bold"
            ></i>
            {{ item.label }}
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <div ref="topScroll" class="top-scroll2" v-if="isNeedTopScrollTail">
      <div class="top-scroll-content2" :style="{ width: topScrollWidth }"></div>
    </div>
    <el-table
      v-show="render"
      ref="table"
      :default-sort="isorter"
      :header-cell-style="{ 'text-align': 'center', padding: '2px' }"
      v-on="$listeners"
      v-bind="$attrs"
      :height="tableHeight"
      :cell-style="{ padding: 2 + 'px' }"
      :cell-class-name="changeCellStyle"
      :row-key="reserveSelection ? 'id' : ''"
    >
      <template v-for="(item, index) in display_columns">
        <el-table-column
          :key="index"
          v-if="item.show && item.type === 'selection'"
          v-bind="item"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :reserve-selection="reserveSelection"
        >
        </el-table-column>
        <el-table-column
          :key="item.prop"
          v-else-if="item.show && item.type === 'index'"
          :prop="item.prop"
          :label="item.label"
          v-bind="item"
          :fixed="item.fixed"
        >
        </el-table-column>
        <el-table-column
          :key="item.prop"
          v-else-if="item.show"
          :prop="item.prop"
          :label="item.label"
          :show-overflow-tooltip="item.over"
          :fixed="item.fixed"
          v-bind="item"
          :sortable="item.sortable"
        >
          <template slot="header">
            <!-- 自定义表头 -->
            <template v-if="item.headerType === 'string'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <el-input
                  clearable
                  class="input"
                  v-model="form[item.prop]"
                  size="small"
                  @input="handleInput"
                  @click.stop.native
                />
              </div>
            </template>
            <template v-else-if="item.headerType === 'remark'">
              <!--                            <span>{{item.label}}</span>-->
              <!--                            <el-tooltip popper-class="tooltip" placement="top">-->
              <!--                                <i class="el-icon-question"></i>-->
              <!--                                <div slot="content" class="tooltip-content">-->
              <!--                                    <div>{{item.detail}}</div>-->
              <!--                                </div>-->
              <!--                            </el-tooltip>-->
              <span>{{ item.label }}</span>
              <el-popover placement="top" trigger="hover">
                <p v-html="item.detail"></p>
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <template v-else-if="item.headerType === 'text'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <el-input
                  clearable
                  class="input"
                  v-model="form[item.prop]"
                  size="small"
                  @input="handleInput"
                  type="textarea"
                  @click.stop.native
                  @keyup.enter.native="handleKeyup"
                />
              </div>
            </template>
            <template v-else-if="item.headerType === 'range'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <el-input
                  clearable
                  class="input"
                  v-model="form[item.prop + 'Start']"
                  size="small"
                  prefix-icon="el-icon-arrow-right"
                  @input="handleInput"
                  @click.stop.native
                />
                <el-input
                  clearable
                  v-model="form[item.prop + 'End']"
                  class="input bottom"
                  size="small"
                  prefix-icon="el-icon-arrow-left"
                  @input="handleInput"
                  @click.stop.native
                />
              </div>
            </template>
            <template v-else-if="item.headerType === 'month'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <el-date-picker
                  class="input time"
                  v-model="form[item.prop]"
                  type="month"
                  size="small"
                  default-time="00:00:00"
                  placeholder="开始时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                  @change="handleInput"
                  @click.stop.native
                >
                </el-date-picker>
              </div>
            </template>
            <template v-else-if="item.headerType === 'datetime'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <el-date-picker
                  class="input time"
                  v-model="form[item.prop + 'Start']"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                  @change="handleInput"
                  @click.stop.native
                >
                </el-date-picker>
                <el-date-picker
                  class="input time bottom"
                  v-model="form[item.prop + 'End']"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="handleInput"
                  @click.stop.native
                >
                </el-date-picker>
              </div>
            </template>
            <template v-else-if="item.headerType === 'select'">
              <div class="input-wrap">
                <span>{{ item.label }}</span>
                <br />
                <f-select
                  filterable
                  clearable
                  class="input"
                  :dict="item.dict"
                  v-model="form[item.prop]"
                  size="small"
                  placeholder="请选择"
                  @change="handleInput"
                >
                </f-select>
              </div>
            </template>
            <!-- 标头需要加必填标识 -->
            <template v-else-if="item.headerType === 'require'">
              <span class="is_require_form">{{ item.label }}</span>
            </template>
            <!-- 默认表头渲染 -->
            <template v-else>{{ item.label }}</template>
          </template>
          <template v-slot="scope" v-if="!item.overFlow">
            <slot v-bind="scope">
              {{ scope.row[scope.column.property] }}
            </slot>
          </template>
          <template v-slot="scope" v-else-if="item.overFlow">
            <slot v-bind="scope">
              <el-popover
                trigger="hover"
                placement="top"
                v-if="
                  scope.row[scope.column.property] &&
                  scope.row[scope.column.property].length > 0
                "
              >
                <!-- 鼠标移动需要提示的其他字段信息（showMessage字段传递对应要展示的字段名称） : 自己的字段超出的鼠标移动展示  -->
                <p>
                  {{
                    item.showMessage
                      ? scope.row[item.showMessage]
                      : scope.row[scope.column.property]
                  }}
                </p>
                <div slot="reference" class="name-wrapper tag-center">
                  <el-tag size="medium" class="tag-ellipsis">{{
                    subLongStr(scope.row[scope.column.property])
                  }}</el-tag>
                </div>
              </el-popover>
              <span v-else></span>
            </slot>
          </template>
          <!-- 如果需要使用到多行表头的时候传递showSlot自己编写 -->
          <slot v-if="showSlot" name="showSlot" :item="item"></slot>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import Tempcclate from "@/views/isystem/Dict";
import LoginVue from "../../views/login/Login.vue";

export default {
  name: "FTable",
  components: { Tempcclate },
  inheritAttrs: false,
  props: {
    columns: {
      type: Array,
      default: () => {},
    },
    isorter: {
      type: Object,
    },
    showOperation: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {},
    },
    tableHeight: {
      type: String,
      default: null,
    },
    showSlot: {
      type: Boolean,
      default: false,
    },
    // 是否要将进口商类型为0-客户，2-医疗进口商的表格背景色显示为绿色
    isImporterTypeBg: {
      type: Boolean,
      default: false,
    },
    // 表格标注背景色的类型,dealMoudule-柜子应付账单，选中的背景标绿
    isTableBgType: {
      type: String,
      default: "",
    },
    // 选中的列id
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    // 是否记录表格的滚动位置，注意一个页面只允许有一个不然会无效
    isRecordTableScroll: {
      type: Boolean,
      default: false,
    },
    // 是否保留多选的值（分页之后）
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    // 是否需要上的滚动条（分页之后）
    isNeedTopScrollTail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableWidth:{
        width:0
      },
      topScrollWidth: 0,
      tableDom: null,
      render: true,
      defaultSort: {},
      form: {},
      display_columns: [],
      tableScrollTop: null, //记录当前表格的滚动距离
    };
  },
  watch: {
    topScrollWidth: {
      // 两个滚动条同时滚动
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.tableDom = this.$refs.table.bodyWrapper
          this.tableDom.addEventListener('scroll', () => {
            // 表格顶部滚动条与底部同步
            let scrollLeft = this.tableDom.scrollLeft
            this.$refs.topScroll.scrollTo(scrollLeft, 0);
          })
          let topScroll = this.$refs.topScroll
          topScroll.addEventListener('scroll', () => {
            // 表格底部滚动条与顶部同步
            let scrollLeft = topScroll.scrollLeft
            this.tableDom.scrollTo(scrollLeft, 0);
          })
        })
      },
      deep: true,
    },
    columns: {
      deep: true,
      handler() {
        this.handleInColumnsChange();
      },
    },
    $route: {
      deep: true,
      handler(to, from) {
        this.$store.state.routes.forEach((item, index) => {
          if (
            item.path == from.path &&
            this.tableScrollTop !== null &&
            this.isRecordTableScroll
          ) {
            let recordTableScrollRouter =
              JSON.parse(sessionStorage.getItem("recordTableScrollRouter")) ||
              {};
            recordTableScrollRouter[from.path] = this.tableScrollTop;
            sessionStorage.setItem(
              "recordTableScrollRouter",
              JSON.stringify(recordTableScrollRouter)
            );
          }
          if (item.path == to.path && this.isRecordTableScroll) {
            let recordTableScrollRouter = JSON.parse(
              sessionStorage.getItem("recordTableScrollRouter")
            );
            this.$nextTick(() => {
              setTimeout(() => {
                this.$refs.table.bodyWrapper.scrollTop =
                  (recordTableScrollRouter &&
                    recordTableScrollRouter[to.path]) ||
                  0;
              }, 12);
            });
          }
        });
      },
    },
  },
  mounted() {
    if (this.isRecordTableScroll) {
      this.$refs.table.bodyWrapper.addEventListener(
        "scroll",
        this.handleScroll,
        false
      );
    }
    this.handleInColumnsChange();
  },
  beforeDestroy() {
    if (this.isRecordTableScroll) {
      this.$refs.table.bodyWrapper.removeEventListener(
        "scroll",
        this.handleScroll,
        false
      );
    }
  },
  methods: {
    // 该方法在获取列表数据后调用
    setScrollWidth() {
      //设置顶部滚顶条宽度值为表格的滚动宽度
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.topScrollWidth = this.$refs.table.bodyWrapper.scrollWidth + 'px';
        }
      })
    },
    handleScrollTop2(){
      if (this.$refs.topScroll) {
        let scrollLeft = this.$refs.topScroll.scrollLeft;
        this.$refs.table.bodyWrapper.scrollTo(scrollLeft,0);
      }
    },
    // 重置表格滚动的顶部距离
    handleScrollTop() {
      this.$refs.table.bodyWrapper.scrollTop = 0;
    },
    handleScroll() {
      this.tableScrollTop = this.$refs.table.bodyWrapper.scrollTop;
    },
    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (
        this.$route.path == "/docpage/VolumeEmailModule" &&
        row.columnIndex == 14
      ) {
        return "table_green_bg";
      }
      // 柜子编辑订单信息进口商类型为0-客户，2-医疗进口商的表格背景色显示为绿色
      if (
        row.row.importerType &&
        ["0", "2"].indexOf(row.row.importerType) !== -1 &&
        this.isImporterTypeBg
      ) {
        return "table_green_bg";
      }
      // // fba优先状态 红色 显示红色
      // if(row.row.showColor === '2') {
      //   return 'table_yellow_bg2'
      // }else if(row.row.showColor === '3') {
      //   return 'table_red_bg2'
      // }
      //敏感品 显示红色
      if (row.row.isMingan === "1") {
        return "table_red_bg2";
      }
      //大森林类型的进口商并且包含销售两个字 标红
      if (row.row.importerType === '1' && row.row.importer && row.row.importer.indexOf('销售') !== -1) {
        return "table_red_bg2";
      }
      // dealMoudule-柜子应付账单，选中的背景标绿
      if (
        this.isTableBgType == "dealMoudule" &&
        this.selectedRowKeys &&
        this.selectedRowKeys.indexOf(row.row.id) !== -1
      ) {
        return "table_green_bg";
      }
      
       if (
        this.$route.path == "/hyFinanceManage/ReceivablesSummary" &&
        !row.row.id && [ 1 , 2 , 8 ].indexOf(row.columnIndex) !== -1
      ) {
        return "bg_collect";
      }
      // 左对齐
      if (row.column.label == "周数" || row.column.label == "目录/文件") {
        // console.log(row.column,'row.column周数')
        return "position_left"; // 修改的样式
      } else {
        return "";
      }
      
     
    },
    handleReset() {
      this.form = {};
    },
    // 为表格头标题添加小图标并hover后出现提示信息
    renderHeadeTime(h, { column, $index }) {
      // h 是一个渲染函数       column 是一个对象表示当前列      $index 第几列
      return h("div", [
        h("span", column.label + "  ", {
          align: "center",
          marginTop: "10px",
        }),
        h(
          "el-popover",
          {
            props: {
              placement: "top-start", // 一般 icon 处可添加浮层说明，浮层位置等属性
              width: "260",
              trigger: "hover",
            },
          },
          [
            h("p", column.detail, {
              class: "text-align: center; margin: 0",
            }),
            h("i", {
              // 生成 i 标签 ，添加icon 设置 样式，slot 必填
              class: "el-icon-question",
              style: "color:#ccc,margin:18px,padding-top:10px",
              slot: "reference",
            }),
          ]
        ),
      ]);
    },
    subLongStr(val) {
      if (val && val.length >= 50) {
        return val.substring(0, 50) + "...";
      } else {
        return val;
      }
    },
    changeTraceEnd(prop) {
      if (this.form[prop + "End"] !== null) {
        //当前值为空 也会进行判断 所以条件最好不要非空验证
        this.form[prop + "Start"] = {
          disableDate: (time) => {
            return time.getTime() > new Date(this.form[prop + "End"]).getTime();
          },
        };
      } else {
        this.form[prop + "Start"] = {};
      }
    },
    //跟踪API结束时间限制开始时间
    changeTraceStart(prop) {
      if (this.form[prop + "Start"] !== null) {
        //当前值为空 也会进行判断 所以条件最好不要非空验证
        this.form[prop + "End"] = {
          disableDate: (time) => {
            return (
              time.getTime() > new Date(this.form[prop + "Start"]).getTime()
            );
          },
        };
      } else {
        this.form[prop + "End"] = {};
      }
    },
    handleCommand(cmd) {
      console.log(cmd,'cmd')
      if (typeof cmd === "number") {
        this.handleColVisible(cmd);
        return;
      }

      cmd === "SAVE" && this.handleSave();
      cmd === "RESTORE" && this.handleRestore();
      cmd === "CLEAR" && this.handleClear();
      this.$refs.dropdowm.hide();
    },
    handleSave() {},
    handleRestore() {
      this.handleInColumnsChange();
    },
    handlePureClear() {
      for (let item in this.form) {
        this.form[item] = "";
      }
    },
    handleClear() {
      this.handlePureClear();
      this.$emit("queryChange", this.form, true, true);
    },
    handleInput() {
      //如果有值，说明是文本框，做字符串分割
      this.$emit("queryChange", this.form, true);
    },
    handleInColumnsChange() {
      this.display_columns = [];
      let CustomColumns = (localStorage.getItem('CustomColumns') && localStorage.getItem('CustomColumns').indexOf('{') !== -1 && JSON.parse(localStorage.getItem('CustomColumns'))) || {};
      for (let i = 0; i < this.columns.length; i++) {
        this.display_columns.push({
          show: (this.columns[i].prop && CustomColumns[this.$route.path] && CustomColumns[this.$route.path].indexOf(this.columns[i].prop) !== -1) ? false : true,
          ...this.columns[i],
        });
      }

      this.handleReRender();
    },
    handleReRender() {
      this.render = false;
      this.$nextTick(() => {
        this.render = true;
        this.$refs.table.doLayout();
      });
    },
    handleColVisible(index) {
      const show = !this.display_columns[index]["show"];
      this.$set(this.display_columns[index], "show", show);
      console.log(this.display_columns[index],this.$route.path, 'this.display_columns[index]');
      if(['/kdorder/NoReceiveGoods', '/kdorder/AllKdOrder'].indexOf(this.$route.path) !== -1) {
        let CustomColumns = (localStorage.getItem('CustomColumns') && localStorage.getItem('CustomColumns').indexOf('{') !== -1 && JSON.parse(localStorage.getItem('CustomColumns'))) || {};
        (CustomColumns && CustomColumns[this.$route.path]) ? '' : (CustomColumns[this.$route.path] = []);
        let pos = this.display_columns[index].prop && CustomColumns[this.$route.path].indexOf(this.display_columns[index].prop)
        if(show && pos !== -1) {
          CustomColumns[this.$route.path].splice(pos, 1);
        } else if(!show && pos == -1) {
          CustomColumns[this.$route.path].push(this.display_columns[index].prop)
        }
        localStorage.setItem('CustomColumns', JSON.stringify(CustomColumns));
      }
      this.handleReRender();

      // if(!show) {
      //     if (this.display_columns[index]['headerType'] === 'datetime' || this.display_columns[index]['headerType'] === 'range') {
      //         this.form[this.display_columns[index]['prop'] + 'start'] = ''
      //         this.form[this.display_columns[index]['prop'] + 'end'] = ''
      //     } else {
      //         this.form[this.display_columns[index]['prop']] = ''
      //     }

      //     this.$emit('queryChange', this.form, false)
      // }
    },
    handleClearSelection() {
      this.$refs.table.clearSelection();
    },
    huixianselected(baseList, selectedList, name) {
      if (this.$refs.table) {
        this.$refs.table.clearSelection();
      }
      baseList.forEach((row) => {
        for (let j in selectedList) {
          if (row[name] == selectedList[j][name]) {
            this.$refs.table.toggleRowSelection(row, true);
            break;
          }
        }
      });
    },
    handleKeyup() {
      this.$emit("handleKeyup");
    },
  },
};
</script>

<style lang="scss">
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 0px;
}
.table-operater-wrap {
  padding: 4px 0;
  max-width: 160px;
  max-height: 500px;
  overflow: hidden auto;

  .el-dropdown-menu__item {
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .divider {
    padding: 8px 16px;
    margin: 4px 0;
    border-top: 1px solid #e8eaec;
    border-bottom: 1px solid #e8eaec;
    font-size: 14px;
  }
}
.el-table th > .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-table .cell {
  padding-left: 0px;
  padding-right: 0px;
}
.el-textarea__inner {
  padding: 2px;
}

.el-table--border th:first-child .cell,
.el-table--border td:first-child .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-table th.el-table__cell > .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-input__inner {
  padding: 0 2px;
}
// 解决fixed设置为left之后，表格头部下边框消失
.el-table th.is-leaf,
.el-table td {
  bottom: 1px;
}
.bg_collect {
  background-color: #f7f7f7;
}
</style>

<style lang="scss" scoped>

// 用于处理tag的单行隐藏
.tag-center {
  height: 100%;
  display: flex !important;
  .tag-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 108px;
  }
}
.general-table-wrap {
  position: relative;

  .operation {
    top: 0;
    right: 0;
    z-index: 10;
    position: absolute;

    .el-button {
      padding: 8px 12px;
    }
  }

  .input-wrap {
    display: inline-block;
    max-width: calc(100% - 36px);
    width: 100%;
    .input {
      display: block;
      // max-width: 200px;
    }

    .time {
      width: 100%;
    }

    .bottom {
      margin-top: 4px;
    }
  }
}

::v-deep .el-table--medium td {
  line-height: 46px;
  padding: 14px 0;

  .el-button {
    padding: 0;
  }
}
.tooltip-content {
  div {
    line-height: 18px;
  }
  div:nth-child(3) {
    padding: 5px 0;
  }
  .indent {
    text-indent: 20px;
  }
}
.bgc {
  background-color: yellowgreen;
}


</style>
