const state = {

}

const mutations = {
    setDict: (state, { key, value }) => {
        if (value){
            state[key] = [ ...value ]
        }

    },
    resetAllDict: (state) => {
        const keys = Object.keys(state)
        for(let i = 0; i < keys.length; i++) {
            state[keys[i]] = null
        }
    },
    delDict:(state,{key})=>{
        // console.log('要删除的缓存',state[key])
        if (state[key]){
            for(let i = 0; i < state[key].length; i++) {
                state[key[i]] = null
            }
        }

    }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
