// const emailPattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
/*const emailPattern = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/*/

const mobilePattern = /^1[0-9]{10}$/

const phonePattern = /^([0-9]{3,4}-)?[0-9]{7,8}$/

const zhognwenPattern = /^[\u4e00-\u9fa5|\s]{0,}$/
const chinesePattern = /^[\u4e00-\u9fa5]{0,}$/

const yingwenPattern = /^[A-Za-z|\s]+$/

const baifenshuPattren = /^(100|[1-9]?\d(\.\d\d?\d?)?)%$/

const urlPattern = /^http[s]?:\/\/.*/

// 匹配非负浮点数（正浮点数 + 0）
const numberPattern = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/

const doublepattern = /^(?=([0-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/

const cardPattern = /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/

const shuziPattern = /^\d+$/

//正整数及最多3位小数(只能小数不能整数)
const sanweixiaoshu = /^(?=([0-9]{1,10}$|[0-9]{1,10}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/

//正整数
const zhengshuPattern = /^[1-9]\d*$/

//正整数及最多三位小数
const zhengshuxiaoshuPartten = /^[0-9]+([.]{1}[0-9]{1,3})?$/

// 父数最多三位小数
const fuzhengshuxiaoshuPartten = /^[-]+[0-9]+([.]{1}[0-9]{1,3})?$/

//英文及数字(单号验证)
const yingwenshuziPartten= /^[A-Za-z0-9]*$/g

const shiweishuPattern = /^\d{10}$/

const mgHsCodePattern = /^\d{4}\.\d{2}\.\d{4}$/

export {
    emailPattern,
    mobilePattern,
    phonePattern,
    urlPattern,
    numberPattern,
    zhognwenPattern,
    yingwenPattern,
    baifenshuPattren,
    doublepattern,
    cardPattern,
    shuziPattern,
    sanweixiaoshu,
    zhengshuPattern,
    zhengshuxiaoshuPartten,
    yingwenshuziPartten,
    chinesePattern,
    shiweishuPattern,
    mgHsCodePattern,
    fuzhengshuxiaoshuPartten
}
