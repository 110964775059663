<template>
    <el-popover
        :width="popoverWidth"
        :visible-arrow="false"
        popper-class="popper-menu"
        placement="bottom"
        trigger="click">
        <div class="c-slim-scrollbar">
            <el-input v-if="filterable" placeholder="请输入" v-model.trim="filterText"> </el-input>
            <el-tree
                ref="tree"
                node-key="id"
                accordion
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :auto-expand-parent="true"
                :expand-on-click-node="false"
                :default-expanded-keys="idArr"
                :filter-node-method="filterNode"
                @current-change="handleCurrentChange">
            </el-tree>
        </div>
        <el-input
            readonly
            clearable
            v-model="name"
            ref="input"
            slot="reference"
            suffix-icon="el-icon-arrow-down"
            :placeholder="placeholder"></el-input>
    </el-popover>
</template>

<script>
export default {
    name: 'TreeDropdown',
    props: {
        value: {
            type: String
        },
        data: {
            type: Array,
            default: () => []
        },
        width: {
            type: Number | String,
            default: '100%'
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        // 是否搜索
        filterable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            idArr:[],
            name: '',
            nameid: '',
            treeData: [],
            dataReference: {},
            popoverWidth: '',
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            filterText: ''
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(nval) {
                this.treeData = [...nval]
                this.dataReference = {}
                this.handleSetReference(nval)
                if(this.value) {
                  this.name = this.value ? this.dataReference[this.value] && this.dataReference[this.value]['title'] : ''
                    this.nameid = this.value || ''
                    this.$refs.tree ? this.$refs.tree.setCurrentKey(this.value) : ''
                }
            }
        },
        value: {
            immediate: true,
            handler(nval) {
                this.$nextTick(() => {
                    this.name = nval ? this.dataReference[nval] && this.dataReference[nval]['title'] : ''
                    this.nameid = nval || ''
                    this.$refs.tree ? this.$refs.tree.setCurrentKey(nval) : ''
                })
            }
        },
        filterText(val) {
          this.filterable ? this.$refs.tree.filter(val) : '';
        }
    },
    mounted() {
        this.popoverWidth = this.width === '100%' ? this.$refs.input.$el.clientWidth : this.width
    },
    methods: {
        handleCurrentChange(node) {
            this.$emit('input', node.id)
        },
        handleSetReference(data) {
            // console.log('小彪子部门',data);
            data.forEach(m=>this.idArr.push(m.id));
            // res.result.forEach(m=>this.idArr.push(m.id));

            for (let i = 0; i < data.length; i++) {
                this.dataReference[data[i]["id"]] = data[i]
                if (data[i]["children"] && data[i]["children"].length) {
                    this.handleSetReference(data[i]["children"]);
                    data[i]["children"].forEach(m=>this.idArr.push(m.id));
                }
            }
            // console.log('所有的节点',this.idArr);
        },
        filterNode(value, data) {
          if(this.filterable) {
            if (!value) return true;
            return data.title.indexOf(value) !== -1;
          } else {
            return true;
          }
        },
    }
}
</script>

<style lang="scss">
.popper-menu {
    padding: 6px 0;
    margin-top: 1px !important;
    > div {
        max-height: 300px;
        overflow: auto;
    }
}
</style>

<style lang="scss" scoped>
::v-deep {
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #e0efff;
    }
}
</style>
