<template>
    <div class="nav-tabs f-flex" ref="headerTwoBox">
    <!-- 输出list数据 -->
        <i class="el-icon-arrow-left" @click="handlePrev"></i>
        <div class="nav-wrap f-flex-1">
        <div class="nav-scroll" ref="nav-scroll" :style="{transform: 'translateX(' + offsetX + 'px)'}">
           <draggable element="ul" :list="routes">
              <span
                    v-for="(item,index) in routes"
                    :key="index">
                    <!-- 提示内容放在标题上 -->
                    <span v-if="item.queryNo" :class="['nav-item', item.closable === false ? 'cannot-close' : '' ]">
                      <router-link :to="item.path" slot="label">{{item.query}}</router-link>
                      <i class="el-icon-close" :name="item.path" @click="handleRemoveItem(index, item.path)"  v-if="item.closable !== false"></i>
                    </span>
                    <!-- 需要鼠标移动提示内容的 -->
                    <el-tooltip v-else-if="item.query" placement="top" :class="['nav-item', item.closable === false ? 'cannot-close' : '' ]">
                      <div slot="content">{{`${item.query}${item.queryNo || ''}`}}</div>
                      <span>
                        <router-link :to="item.path" slot="label">{{item.title}}</router-link>
                        <i class="el-icon-close" :name="item.path" @click="handleRemoveItem(index, item.path)"  v-if="item.closable !== false"></i>
                      </span>
                    </el-tooltip>
                    <span v-else :class="['nav-item', item.closable === false ? 'cannot-close' : '' ]">
                      <router-link :to="item.path" slot="label">{{item.title}}</router-link>
                      <i class="el-icon-close" :name="item.path" @click="handleRemoveItem(index, item.path)"  v-if="item.closable !== false"></i>
                    </span>
                </span>
            </draggable>
              
            </div>
        </div>
        <i class="el-icon-arrow-right" @click="handleNext"></i>
    </div>
</template>

<script>
import { mapState } from 'vuex'
//导入draggable组件
import draggable from "vuedraggable";
export default {
    name: 'NavTabsBar',
    data() {
        return {
            offsetX: 0,
        }
    },
    components: {
    draggable,
    },
    watch: {
        '$route': {
            deep: true,
            handler() {
                this.handleSlideTab()
            }
        }
    },
    computed: {
        ...mapState(['routes']),
        ...mapState(["navHeight"]),
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.$nextTick(() => {
          this.$store.commit("setNavHeight", {headerTopBox: this.navHeight.headerTopBox ,headerTwoBox: (this.$refs.headerTwoBox && this.$refs.headerTwoBox.offsetHeight) || 44})
          console.log(this.$refs.headerTwoBox.offsetHeight,this.navHeight, '顶部导航栏高度')
        })
        //窗口尺寸改变
        window.onresize = () => {
          return (() => {
            this.$nextTick(() => {
              this.$store.commit("setNavHeight", {headerTopBox: this.navHeight.headerTopBox ,headerTwoBox: (this.$refs.headerTwoBox && this.$refs.headerTwoBox.offsetHeight) || 44})
              console.log(this.$refs.headerTwoBox.offsetHeight,this.navHeight, '顶部导航栏高度')
            })
          })();
        };
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        // 删除头部导航标签
        handleRemoveItem(index, path) {
            let nextTab = {}
            this.routes.forEach((tab, index) => {
                if(tab.path === path) {
                    nextTab = this.routes[index + 1] || this.routes[index - 1] // 获取当前删除标签的前后标签
                }
            })
            this.$store.dispatch('delRoute', { index, path })
            if(this.$route.fullPath === path) { // 如果当前路由地址等于要删除的地址
                this.$router.push(nextTab.path) // 导航到新路由
            } else {
                this.$nextTick(() => {
                    this.handleResize()
                })
            }
        },
        handlePrev() {
            this.$router.go(-1);
            if(this.offsetX === 0) {
                return 
            }
            const absolute_x = this.offsetX * -1
            const offsetWidth = this.handleGetElOffsetWidth()
            if(absolute_x <= offsetWidth) {
                this.offsetX = 0
            } else {
                this.offsetX = -1 * (absolute_x - offsetWidth)
            }
        },
        handleNext() {
            const $el = this.$refs['nav-scroll']
            const absolute_x = this.offsetX * -1
            if($el.offsetWidth + absolute_x === $el.scrollWidth) {
                return
            }
            const right_x = $el.scrollWidth - ($el.offsetWidth + absolute_x)
            if(right_x <= $el.offsetWidth) {
                this.offsetX -= right_x
            } else {
                this.offsetX -= $el.offsetWidth
            }
        },
        // 切换tab页和导航标签
        handleSlideTab() {
            if(this.$route.path === this.routes[this.routes.length - 1]['path']) {
                this.$nextTick(() => {
                    if(this.handleIsNotFull()) {
                        return
                    }
                    const offsetWidth = this.handleGetElOffsetWidth()
                    const size_x = this.handleGetSizeX()
                    this.offsetX = -1 * (size_x - offsetWidth)
                })
            } else {
                this.$nextTick(() => {
                    if(this.handleIsNotFull()) {
                        return
                    }
                    this.handleMoveTab()
                })
            }
        },
        handleMoveTab() {
            const offsetWidth = this.handleGetElOffsetWidth()
            const absolute_x = this.offsetX * -1
            let item_left = 0
            let item_right = 0

            const $el = this.$refs['nav-scroll']
            const $spanArr = $el.getElementsByClassName('nav-item')
            let index = -1
            for(let j = 0; j < this.routes.length; j++) {
                if(this.$route.path === this.routes[j]['path']) {
                    index = j
                    break
                }
            }
            for(let i = 0; i <= index; i++) {
                item_left = item_right
                item_right += $spanArr[i].offsetWidth + 8
            }

            if(item_left < absolute_x) {
                this.offsetX = -1 * item_left
            } else if(item_right > absolute_x + offsetWidth) {
                this.offsetX = -1 * (item_right - offsetWidth)
            }
        },
        handleResize() {
            if(this.handleIsOutOfPlace()) {
                if(this.handleIsNotFull()) {
                    this.offsetX = 0
                } else {
                    const offsetWidth = this.handleGetElOffsetWidth()
                    const size_x = this.handleGetSizeX()
                    this.offsetX = -1 * (size_x - offsetWidth)
                }
            }
        },
        handleIsNotFull() {
            const offsetWidth = this.handleGetElOffsetWidth()
            const size_x = this.handleGetSizeX()
            if(size_x < offsetWidth) {
                return true
            }
            return false
        },
        handleIsOutOfPlace() {
            const offsetWidth = this.handleGetElOffsetWidth()
            const absolute_x = this.offsetX * -1
            const size_x = this.handleGetSizeX()
            if(absolute_x > 0 && size_x - absolute_x  < offsetWidth) {
                return true
            }
            return false
        },
        handleGetElOffsetWidth() {
            const $el = this.$refs['nav-scroll']
            return $el.offsetWidth
        },
        handleGetSizeX() {
            const $el = this.$refs['nav-scroll']
            const $spanArr = $el.getElementsByClassName('nav-item')
            let size_x = 0
            for(let i = 0; i < $spanArr.length; i++) {
                size_x += $spanArr[i].offsetWidth + 8
            }
            return size_x
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-tabs {
    height: 44px;
    top: 40px;
    left: 150px;
    right: 0;
    z-index: 2000;
    position: fixed;
    transition: all 0.3s ease-in-out;
    background: #f4f3f7;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
        height: 34px;
        padding: 0 8px;
        line-height: 33px;
        cursor: pointer;
        font-weight: bold;
    }
    .nav-wrap {
        overflow: hidden;
        white-space: nowrap;
        .nav-scroll {
            transition: 0.3s linear;
        }
    }
    .nav-item {
        padding: 0 !important;
        margin: 0 4px;
        border: none;
        background: #ffffff;
        position: relative;
        &.cannot-close {
            a {
                padding: 0 16px;
            }
        }

        a.router-link-exact-active {
            color: #409EFF;
            &:hover {
                color: #409EFF;
            }
            .el-icon-close {
                color: #909399;
            } 
        }
        a {
            height: 100%;
            padding: 0 32px 0 16px;
            display: inline-block;
            text-decoration: none;
            line-height: 34px;
            outline: none;
            font-size: 14px;
            background: #fff;
            color: #808695;
            
            &:hover {
                color: #515a6e; 
            }
        }
        .el-icon-close {
            padding: 1px;
            top: 0px;
            right: 10px;
            position: absolute;
            cursor: pointer;
            border-radius: 50%;
            font-size: 12px;
            color: #909399;
            &:hover {
                color: #fff;
                background-color: #c0c4cc;
            }
        }
    }
}
</style>
