<template>
    <div class="containers" ref="content">

        <el-row>

            <el-button-group>
                <el-button @click="handlerZoom(0.1)">放大</el-button>
                <el-button @click="handlerZoom(-0.1)">缩小</el-button>
                <el-tooltip  effect="light" :content="this.simulationStatus ? '退出模拟' : '开启模拟'">
                    <el-button  icon="el-icon-cpu" @click="processSimulation">
                        模拟
                    </el-button>
                </el-tooltip>

            </el-button-group>
        </el-row>

        <div class="canvas" ref="canvas"></div>
        <div id="js-properties-panel" class="panel"></div>
    </div>

</template>

<script>

    // 左边工具栏以及编辑节点的样式
    import 'bpmn-js/dist/assets/diagram-js.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

    // 右边工具栏样式
    import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';

    import tokenSimulation from "bpmn-js-token-simulation";
    // import {addDeploymentByString} from '@/api/activiti'
    import {getAction} from '@/api/manage'
    import { CustomViewer } from "../activity/CustomViewer";



    import bpmnViewer from "bpmn-js/lib/Viewer";


    export default {
        components: {
        },
        props: {
            simulation: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                bpmnViewer:null,
                container: null,
                canvas: null,
                scale: 1,
                simulationStatus: false,
                nodeList: [],
                url: {
                    view:'/activiti/view',
                    history:'/activiti/history'
                },
                actionType:'add'
            }
        },
        computed: {
            additionalModules() {
               //  const Modules = [];
               //  Modules.push(tokenSimulation);
               //
               //
               //  Modules.push(ExampleModule)
               //  Modules.push(TokenSimulationModule)
               // return Modules;
            }
        },
        created() {

        },

        mounted() {
            this.init();
        },
        methods: {
            /**
             * 初始化流程设计器对象
             * @returns {Promise<void>}
             */
            async init() {

                // 获取到属性ref为“content”的dom节点
                this.container = this.$refs.content
                // 获取到属性ref为“canvas”的dom节点
                const canvas = this.$refs.canvas
                // 创建bpmnViewer
                this.bpmnViewer = new CustomViewer({
                    container: canvas,
                    additionalModules: this.additionalModules,
                        // tokenSimulation,
                        // ExampleModule,

                });

                //根据url 获取响应的动作
                let type = this.getQueryVariable("type")
                let xml = null;
                // 创建新流程
                if(type == 'add') {
                }else if(type == 'history'){
                    this.actionType = 'history'
                    let result =null;
                    await getAction(this.url.history, {processInstanceId:this.getQueryVariable("processInstanceId")}).then(res => {
                        if(res.success){
                            xml = res.result['xml'];
                            this.createNewDiagram(xml);
                            result = res.result

                        }
                    })
                    const canvas = this.bpmnViewer.get('canvas');
                    canvas.zoom("fit-viewport", "auto");
                    const elementRegistry = this.bpmnViewer.get('elementRegistry');

                    result['highLine'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            canvas.addMarker(elementToColor, 'highlight');
                        }
                    });
                    result['highPoint'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            canvas.addMarker(elementToColor, 'highlightPOINT');
                        }
                    });
                    result['waitingToDo'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            canvas.addMarker(elementToColor, 'highlightTODO');
                        }
                    });
                    result['iDo'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            canvas.addMarker(elementToColor, 'highlightIDO');
                        }
                    });
                }
            },

            /**
             * 创建新流程
             * @param bpmn BPMN流程XML报文
             * @returns {Promise<void>}
             */
            async createNewDiagram(bpmn) {
                try {
                    const result = await this.bpmnViewer.importXML(bpmn);
                    const {warnings} = result;
                    console.log(warnings);
                    this.success();
                } catch (err) {
                    console.log(err.message, err.warnings);
                }
            },
            success() {
                this.addModelerListener();
                this.addEventBusListener();
            },
            addModelerListener() {
                const that = this;
                that.bpmnViewer.on("element.click", e => {
                    console.log("modelerListener", e);
                });
            },
            addEventBusListener() {
                const that = this;
                const eventBus = this.bpmnViewer.get("eventBus");
                eventBus.on("element.click", function (e) {
                    that.elementClick(e);
                });
            },
            elementClick(e) {
                const that = this;
                if (e.element.businessObject.$type === "bpmn:StartEvent") {
                    console.log(
                        "这是一个开始节点",
                        e.element.businessObject.id,
                        e.element.businessObject.$type,
                        e.element.businessObject.name
                    );

                }
                if (e.element.businessObject.$type === "bpmn:UserTask") {
                    console.log(
                        "这是一个用户节点",
                        e.element.businessObject.id,
                        e.element.businessObject.$type,
                        e.element.businessObject.name
                    );
                }
            },
            //获取url参数
            getQueryVariable(variable){
                var query = window.location.search.substring(1);
                console.log(query)
                var vars = query.split("&");
                for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){return pair[1];}
                }
                return(false);
            },
            handlerRedo() {
                this.bpmnViewer.get("commandStack").redo();
            },
            handlerUndo() {
                this.bpmnViewer.get("commandStack").undo();
            },
            handlerZoom(radio) {
                const newScale = !radio ? 1.0 : this.scale + radio;
                this.bpmnViewer.get("canvas").zoom(newScale);
                this.scale = newScale;
            },
            processSimulation() {
                this.simulationStatus = !this.simulationStatus;
                this.simulation && this.bpmnViewer.get("toggleMode").toggleMode();
            },
        },
        computed: {}
    }
</script>
<style lang="scss" >
    .highlight .djs-visual > :nth-child(1) {
        stroke: green !important;
        fill: green !important;
    }
    .highlightPOINT .djs-visual > :nth-child(1) {
        stroke: gray !important;
        fill: #eae9e9 !important;
    }
    .highlightIDO .djs-visual > :nth-child(1) {
        stroke: green !important;
        fill: #a3d68e !important;
    }
    .highlightTODO .djs-visual > :nth-child(1) {
        stroke: green !important;
        fill: yellow !important;
    }
    .containers {
        position: absolute;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        padding: 5px;
    }
    .canvas {
        width: 100%;
        height: 100%;
    }
    .panel {
        position: absolute;
        right: 0;
        top: 0;
        width: 300px;
    }
</style>
