import { getAction, postAction } from './manage'

export function login(data) {
  return postAction('/sys/login', data)
}

export function loginWithNewUserName(data) {
    return getAction('/sys/loginWithNewUserName', data)
}

export function getRandom(currentTime) {
    return getAction(`/sys/randomImage/${currentTime}`)
}

export async function getPermissionList() {
    const token = localStorage.getItem('TOKEN')
    return await getAction( `sys/permission/getUserPermissionByToken?token=${token}`)
}
