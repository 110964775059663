<template>
    <div class="f-full-height">
        <keep-alive>
            <router-view v-if="keepAlive" />
        </keep-alive>
        <router-view v-if="!keepAlive" />
    </div>
</template>

<script>
export default {
    name: 'BlankLayout',
    computed: {
      keepAlive () {

        // console.log("路由",this.$route.meta.keepAlive)
        return this.$route.meta.keepAlive
      }
    },
}
</script>

<style scoped>

</style>
