
import { getAction } from "@/api/manage";

export default {
  setSidebarList: (state, list) => {
    state.sidebarList = list
  },
  setPermissionList: (state, list) => {
    state.permissionList = list
  },
  setRoutes: (state, routes) => {
    state.routes = [
      {
        title: '首页',
        path: '/',
        closable: false
      }
    ]
    state.routes = routes
  },
  setUpRoute(state, route) {
    state.upRoute = route
  },
  setMessageQuery(state, query) {
    state.messageQuery = query
  },
  setToken(state, token) {
    state.token = token
  },
  setFullscreenLoading(state, fullscreenLoading) {
    state.fullscreenLoading = fullscreenLoading
  },
  setNavHeight(state, navHeight) {
    state.navHeight = navHeight
  },
  addRoute: (state, route) => {
    // state.routes.splice(1,state.routes.length-1) // 每次保留routes的第一个元素，删除后面的元素
    if (route.path === '/') { // 判断是否为首页
      return;
    } else {
      // console.log('地址', route)
      // 地址？之后的值不同新增一个标签页
      var url1 = route.path;
      for (let i = 0; i < state.routes.length; i++) {
        var url2 = state.routes[i].path;
        // console.log('地址2', url2);
        if (url1 && url2 && url1 == url2) {
          state.routes[i].path = route.path;
          return;
        } else if (route.path == state.routes[i].path) {
          return;
        }
      }
      //   地址？之后的值不同不新增一个标签页
      //   var url1 = route.path.substring(0, route.path.indexOf("?"))
      //   console.log('地址1', url1);
      //   for (let i = 0; i < state.routes.length; i++) {
      //     var url2 = state.routes[i].path.substring(0, state.routes[i].path.indexOf("?"));
      //     console.log('地址2', url2);
      //     if (url1 && url2 && url1 == url2) {
      //       state.routes[i].path = route.path;
      //       return;
      //     } else if (route.path == state.routes[i].path) {
      //       return;
      //     }
      //   }
      state.routes.push(route) //不是的话就添加当前标签
      // state.routes = new Set(state.routes)
      // console.log('标签数组', state.routes)
    }
  },
  addRouteMap: (state, route) => {
    state.routesMap[route['path']] = route.title
  },
  delRoute: (state, index) => {
    state.routes.splice(index, 1)
  },
  delRouteMap: (state, path) => {
    delete state.routesMap[path]
  },
}
