<template>
    <div class="main-full-content">
        <el-divider content-position="left">供应商编辑</el-divider>
        <el-tabs tab-position="left" @tab-click="handleClick" v-model="clickLabel">
            <el-tab-pane
                :name="tab.label"
                v-for="(tab, index) in tabs"
                :key="index"
                :label="tab.label"
                :lazy="true"
                v-if="(isShow && tab.label!=='子账号') || tab.label === '基本信息' || ( isShow && tab.label==='子账号' && !parentId)"
            >
                <component :is="tab.component" v-if="tab.show"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BasicModule from './modules/BasicModule'
import HistoryModule from './modules/HistoryModule'
import AccountModule from './modules/AccountModule'
import AccountantModule from './modules/AccountantModule'
import FileModule from './modules/FileModule'
import {getAction} from "@/api/manage";
import SonAccountModule from './modules/SonAccountModule'
export default {
    name: 'Edit',
    components: {
        BasicModule,
        HistoryModule,
        AccountModule,
        AccountantModule,
        FileModule,
        SonAccountModule
    },
    data() {

        return {
            tabs: [
                { label: '基本信息', component: 'BasicModule', show: true },
                { label: '子账户', component: 'SonAccountModule', show: false },
                { label: '历史记录', component: 'HistoryModule', show: false },
                { label: '银行账户信息', component: 'AccountModule', show: false },
                { label: '联系人', component: 'AccountantModule', show: false },
                { label: '文件管理', component: 'FileModule', show: false },
            ],
            isShow: !!this.$route.query.id,
          parentId:'',
          url:{
              getbyid:'/sys/supplier/queryById',
          },
          clickLabel: '基本信息'
        }
    },
    watch: {
      '$route': {
          deep: true,
          handler() {
              this.initVue(this.$route.query.id);
              this.clickLabel = '基本信息';
              this.tabs.forEach((item, index) => {
                item.show = false;
              })
              setTimeout(() => {this.tabs[0].show = true;},50);
          }
      }
    },
    methods:{
        handleClick(tab){
            console.log('点击选中的tab',tab);

            this.tabs.map(t=>{
                if (t.label !== tab.label){
                    t.show = false;
                }else {
                    t.show = true;
                }
            })
        },
        initVue(id) {
          if (id){
            getAction(this.url.getbyid,{id:this.$route.query.id})
                .then(res=>{
                  this.parentId = res.result.parentId;
                })
          }
        },
    },
  created() {
      this.initVue(this.$route.query.id);
  }
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
    margin-top: 16px;
    margin-bottom: 20px;
    .el-divider__text {
        font-size: 20px;
    }
}
</style>
