// 是否
const yesorno =  [
    { value: 0, label: '否' },
    { value: 1, label: '是' },
]

// 客户等级
const grade =  [
    { value: 0, label: 'VIP客户' },
    { value: 1, label: '重点客户' },
    { value: 2, label: '一般客户' },
    { value: 3, label: '潜在客户' },
    { value: 4, label: '新客户' },
]

// 付款方式
const payType =  [
    { value: 0, label: '信用付款' },
    { value: 1, label: '单票送货前结算' },
    { value: 2, label: '单票送货后结算' },
]

// 提成类型
const commission = [
    { value: 0, label: '自行开发' },
    { value: 1, label: '公司客户' },
    { value: 2, label: '团体客户' },
    { value: 3, label: '扶持区域' },
]

// 注册性质
const regType = [
    { value: 0, label: '企业' },
    { value: 1, label: '个人' },
]

// 客户类型
const userType = [
    { value: 0, label: '同行' },
    { value: 1, label: '直客' },
]

export {
    yesorno,
    grade,
    payType,
    commission,
    regType,
    userType,
}
