import { login,loginWithNewUserName } from '@/api/user'
import store from "@/store";

const state = {
  token: localStorage.getItem('TOKEN') || '',
  userInfo: JSON.parse(localStorage.getItem('USERINFO')) || {}
}

const mutations = {
  setToken: (state, payload) => {
    state.token = payload
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload
  }
}

const actions = {
  loginWithNewUserName({ commit }, params) {
    return loginWithNewUserName(params).then((res) => {
      if (res.success) {
        store.commit('setToken', res.result.token)
        commit('setToken', res.result.token)
        commit('setUserInfo', res.result.userInfo)
        localStorage.setItem('TOKEN', res.result.token)
        localStorage.setItem('USERINFO', JSON.stringify(res.result.userInfo))
      }

    })
  },
  login({ commit }, params) {
    return login(params).then((res) => {
      store.commit('setToken', res.result.token)
      commit('setToken', res.result.token)
      commit('setUserInfo', res.result.userInfo)
      localStorage.setItem('TOKEN', res.result.token)
      localStorage.setItem('USERINFO', JSON.stringify(res.result.userInfo));
    })
  },
  resetToken({ commit }) { // 清空令牌
    return new Promise(resolve => {

      commit('setToken', '')
      commit('setUserInfo', {})

      localStorage.removeItem('TOKEN')
      localStorage.removeItem('USERINFO')
      resolve()
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
