<template>
    <el-dialog
        center
        width="30%"
        :close-on-click-modal="false"
        :title="row.id ? '编辑' : '新增'"
        append-to-body
        :visible.sync="visible"
        :before-close="handleClose"
        >
        <div class="content-wrap c-slim-scrollbar">
            <el-form :model="form" :rules="rules" ref="form" label-width="60px" size="medium">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="itemText" label="文字">
                            <el-input v-model="form.itemText"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="itemValue" label="数值">
                            <el-input v-model="form.itemValue"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="description" label="描述">
                            <el-input v-model="form.description" type="textarea" rows="2"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="sortOrder" label="排序">
                            <el-input v-model="form.sortOrder"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="启用">
                            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="drawer_footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import { intValidator } from '@/utils/validator'
export default {
    name: 'DictItemDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
                itemText: [
                    { required: true, message: '请输入文字', trigger: 'change' },
                ],
                itemValue: [
                    { required: true, message: '请输入数值', trigger: 'change' },
                ],
                sortOrder: [
                    { validator: intValidator, trigger: 'change' }
                ]
            },
            url: {
                add: '/sys/dictItem/add',
                edit: '/sys/dictItem/edit',
            }
        }
    },
    watch: {
        visible(val) {
            if(val) {
                this.form = { ...this.row }
                if (!this.row.id && (this.form.status === 1)){
                    this.$nextTick(() => {
                        this.$refs.form.clearValidate()
                    })
                }
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add;
            console.log('爷爷的数据',this.row);
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        //关闭之前清空对应的前端缓存
                        //同时清空sessionstorage的
                        localStorage.removeItem("dict:" + this.row.dictCode);
                        this.handleClose();
                        this.$emit('ok');
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
