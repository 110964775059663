<template>
    <el-dialog
        :title="'字典配置：' + form.dictName + ':' + form.dictCode"
        :visible.sync="visible"
        width="800px"
        top="100px"
        custom-class="c-custom-dialog"
        :destroy-on-close="true"
        :close-on-click-modal="false"

        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="auto" size="medium"  >
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="type" label="类型" >
                        <el-radio-group v-model="form.type">
                            <el-radio :label="0">SQL类型</el-radio>
                            <el-radio :label="1">自定义类型</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="activeSql" label="是否启用">
                        <el-radio-group v-model="form.activeSql">
                            <el-radio :label="'0'">不启用</el-radio>
                            <el-radio :label="'1'">启用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="isStore" label="是否缓存">
                        <el-radio-group v-model="form.isStore">
                            <el-radio :label="'0'">否</el-radio>
                            <el-radio :label="'1'">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <p class="title f-m-t-0">1. SQL语句</p>
                    <div class="CodeMirror-wrap">
                        <codemirror v-model="form.sqlCode" :options="options"></codemirror>
                    </div>
                </el-col>
                <el-col :span="24">
                    <p class="title">2. 自定义类型</p>
                    <div class="f-p-b-10">
                        <el-button type="primary" size="mini" @click="handleEdit({})">新增</el-button>
                    </div>
                    <f-table
                        ref="GTable"
                        border
                        height="290"
                        size="small"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :highlight-current-row="true"
                        :columns="columns"
                        :data="tableData"
                        :cell-style="{ 'text-align': 'center' }">
                        <template v-slot="scope">
                            <template v-if="scope.column.property === 'action'">
                                <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                                <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
                            </template>
                            <template v-else-if="scope.column.property === 'status'">
                              <span style="color:red" v-if="scope.row.status === 0">否</span>
                              <span v-else>是</span>
                            </template>
                        </template>
                    </f-table>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>

        <dict-item-dialog :visible.sync="itemVisible" :row="itemRow" @ok="handleGetTableData" ></dict-item-dialog>
    </el-dialog>
</template>

<script>
import { getAction, deleteAction, putAction } from '@/api/manage'
import DictItemDialog  from './DictItemDialog'

import { codemirror } from 'vue-codemirror'
import 'codemirror/addon/scroll/simplescrollbars.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/scroll/simplescrollbars.css'
import { updatedDict } from '@/utils/util';

const form = {
    sqlCode: '',
    activeSql: ''
}

export default {
    name: 'DictConfigureDialog',
    components: {
        codemirror,
        DictItemDialog
    },
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            itemRow: {},
            itemVisible: false,
            tableData: [],
            form: { ...form },
            rules: {
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                isStore:[
                    {required:true,message:'请选择是否缓存',trigger:'change'}
                ],
            },
            options: {
                line: true,
                lineNumbers: true,
                scrollbarStyle: 'simple',
            },
            placeholder: '请在此输入x-sql代码',
            columns: [
                {
                    type: 'index',
                    label: '#'
                },
                {
                    prop: 'itemText',
                    label: '文字'
                },
                {
                    prop: 'itemValue',
                    label: '数值'
                },
                {
                    prop: 'status',
                    label: '启用'
                },
                {
                    prop: 'sortOrder',
                    label: '排序'
                },
                {
                    prop: 'action',
                    label: '操作'
                },
            ],
            url: {
                list: '/sys/dictItem/list',
                delete: '/sys/dictItem/delete',
                edit: '/sys/dict/edit'
            },
        }
    },
    watch: {
        visible(val) {
            if(val) {
                const { id, dictName, dictCode, description, sqlCode, type, activeSql,isStore } = this.row
                this.form = { id, dictName, dictCode, description, sqlCode, type, activeSql,isStore }
                if(sqlCode === null || sqlCode === undefined) {
                    this.form.sqlCode = ''
                }
                //默认 不缓存 自定义类型 不启用sql
                if (!this.form.type) {
                    this.$set(this.form,'type',1);
                }
                if (!this.form.activeSql) {
                    this.$set(this.form,'activeSql','0');
                }
                if (!this.form.isStore) {
                    this.$set(this.form,'isStore','0');
                }
                this.handleGetTableData()
            }
        }
    },
    methods: {
        handleDel({id}) {
             this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.delete, {id}).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleGetTableData()
                })
            }).catch(() => {

            })
        },
        handleEdit(row) {

            this.itemRow = { ...row, dictId: this.row.id,dictCode:this.row.dictCode };
            console.log('父亲的数据',row,this.itemRow);
            this.itemVisible = true
        },
        handleGetTableData() {
            const params = {
                dictId: this.form.id,
                column: 'sortOrder',
                order: 'asc'
            }
            getAction(this.url.list, params).then(res => {
                this.tableData = res.result
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    putAction(this.url.edit, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        });
                        //关闭之前清空对应的前端缓存

                        //同时清空sessionstorage的
                        sessionStorage.removeItem("dict:" + this.row.dictCode);
                        // 更新对应的dict
                        updatedDict(this.row.dictCode); //参数为更新缓存中数据字典的值data传递key值，可以支持多个逗号分隔（如bookAgent_id_chinese）
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.form = { ...form }
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss">
.CodeMirror-wrap {
    height: 180px;
    border: 1px solid #dddddd;
    .vue-codemirror {
        height: 100%;
        .CodeMirror {
            height: 100% !important;
            font-size: 16px;
            font-family: inherit;
        }
    }
}
</style>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px;
    }
.title {
    margin-bottom: 10px;
    font-weight: bold;
}

td {
    .el-button {
        padding: 6px 0;
    }
}
</style>
