import { getPermissionList } from '@/api/user'
import { generateRoutes, generateSibarData } from '@/utils/route'
import {getAction} from "@/api/manage";

export default {
    getPermissionList({ commit }) {
        return new Promise(async resolve => {
            await getPermissionList().then(async res => {
                const route = generateRoutes(res.result.menu)
                const sidebar = generateSibarData(res.result.menu)

                commit('setSidebarList', sidebar)
                commit('setPermissionList', route)
                await getAction('/sys/button/auth/getPermission')
                    .then(res => {
                        if (res.result) {
                            // console.log('res.result.length',Object.keys(res.result).length);
                            // this.$root.btnObj = res.result;
                            localStorage.setItem('btnPermission', JSON.stringify(res.result));
                            console.log('刷新按钮权限成功')
                        }

                    }).catch(err=>{
                    console.log('刷新按钮权限失败了！！！',err);
                })
                resolve(route)
            })
        })
    },
    addRoute({ commit }, payload) {
        const { fullPath , meta }  = payload
        const obj = {
            title: meta.title,
            path: fullPath
        }
        commit('addRoute', obj)
        commit('addRouteMap', obj)
    },
    setRoutes({ commit }, orderList) {
        commit('setRoutes', orderList)
    },
    delRoute({ commit }, { index, path }) {
        commit('delRoute', index)
        commit('delRouteMap', path)
    }
}
