<template >
    <div class="login-wrap">
        <img :src="imgSrc" width="100%" height="100%" alt="" />
        <div class="wrap">
            <p class="f-text-center title">FBA2.0</p>
            <p class="f-text-center sub-title">后台登录入口</p>
            <div class="form-wrap">
                <el-form :model="form" :rules="rules" label-position="top" ref="form" @keyup.enter.native="handleLogin">
                    <el-form-item prop="username">
                        <el-input type="text" v-model="form.username" prefix-icon="el-icon-user" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" v-model="form.password" prefix-icon="el-icon-lock" clearable></el-input>
                    </el-form-item>
                    <div class="f-flex">
                        <el-form-item prop="captcha">
                            <el-input type="text" v-model="form.captcha" placeholder="验证码" clearable>
                                <template v-slot:prefix>
                                    <div class="prefix-wrap">
                                        <img src="@assets/code.png"/>
                                    </div>
                                </template>
                            </el-input>
                        </el-form-item>
                        <div class="checkcode-wrap">
                            <img class="image" :src="captchaUrl" @click="handleCaptcha"/>
                        </div>
                    </div>
                    <div class="f-text-center btn-wrap">
                        <el-button type="primary" @click="handleLogin">登录</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { getRandom } from "@/api/user"
    import router from "../../router";
    import {getAction} from "../../api/manage";
    import store from "@/store";
    import { setPermissionListAll } from '@/utils/util';

    export default {
        name: 'Login',
        data() {
            const validateUname = (rule, value, callback) => {
                if (value.trim() === '') {
                    callback(new Error('请输入用户名'));
                }
                callback();
            }
            const validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入密码'));
                }
                callback();
            }
            const validateCaptcha = (rule, value, callback) => {
                if (value.trim() === '') {
                    callback(new Error('请输入验证码'));
                }
                if(value.trim().length !== 4) {
                    callback(new Error('请输入4位验证码'));
                }
                callback();
            }
            return {
                imgSrc:require('@assets/bg.gif'),
                form: {
                    username: '',
                    password: '',
                    captcha: '',
                    checkKey: '',
                    type:'1'
                },
                rules: {
                    username: [
                        { validator: validateUname, trigger: 'blur' }
                    ],
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    captcha: [
                        { validator: validateCaptcha, trigger: 'blur'}
                    ],
                },
                timer: null,
                expires: 60 * 1000,
                captchaUrl: '',
                captchaSuccess: false,
                url:{
                    list: '/sys/dict/list',
                    dict: '/sys/dict/getDictItems/',
                    getAllDict:'/sys/dict/getAllDictCodeItems',
                },
                dictlist:[],
            };
        },
        created() {
            this.handleCaptcha()
        //    this.handleSetInterval()
        },
        beforeDestroy() {
            // this.handleClearInterval()
        },

        methods: {

            handleLogin() {
              let Base64 = require('js-base64').Base64;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                      //进行密码加密

                      // this.form.password = Base64.encode(this.form.password);
                      //
                      // console.log("前端加密",this.form.password);
                        store.commit("setSidebarList", []);
                        this.$store
                            .dispatch('user/login', this.form)
                            .then(() => {
                                // 当前用户可以访问的路由
                                setPermissionListAll().then(res => {
                                  console.log('权限获取成功');
                                  this.$router.push({
                                      path: this.$route.query.redirect || "/"
                                  })
                                  if (localStorage.getItem("USERINFO")) {
                                    let userInfo = JSON.parse(localStorage.getItem("USERINFO"));
                                    if (userInfo.pwd == 'forest123') {
                                      console.log('密码是初始密码');
                                      this.$message.warning('此账号为初始密码，请修改密码之后再进行操作');
                                      localStorage.setItem("init_password", 'true');
                                      this.$router.push('/');
                                    } else if (userInfo.pwd == 'yes') {
                                      localStorage.removeItem('init_password');
                                      console.log('密码不是初始密码');
                                    }
                                  }
                                }).catch(err => {

                                });
                            }).catch(err=>{
                              // this.form.password = Base64.decode(this.form.password);
                        })
                    }
                });
            },
            // handleGetCaptcha() {
            //     this.handleClearInterval()
            //     this.handleSetInterval()
            // },

            handleCaptcha() {
                this.form.checkKey = new Date().getTime();
                getRandom(this.form.checkKey).then(res => {
                    if (res.success) {
                         console.log(res)
                        this.captchaUrl = res.result
                        this.captchaSuccess = true
                    } else {
                        this.captchaSuccess = false
                    }
                }).catch(() => {
                    this.captchaSuccess = false
                })
            },
            // handleSetInterval() {
            //     this.handleCaptcha()
            //     this.timer = setInterval(() => {
            //         this.handleCaptcha()
            //     }, this.expires)
            // },
            // handleClearInterval() {
            //     clearInterval(this.timer)
            // }
        },
        beforeRouteLeave (to, from, next) {
            let btnObj = this.$root.btnObj;
                //缓存
                // getAction(this.url.getAllDict)
                //     .then(res=>{
                //         console.log('res.result',res.result);
                //         for (var name in res.result){
                //             if (res.result && res.result[name] && res.result[name].length<1000) {
                //                 sessionStorage.setItem('dict:' + name,JSON.stringify(res.result[name]));
                //             }
                //
                //         }
                //     })
            this.$initBtnPermission();
            next()
        },
    }

</script>

<style lang="scss" scoped>
    .login-wrap {
        height: 100%;
        background: #707773;
        position: relative;
        overflow: auto;
        .wrap {
            width: 400px;
            height: 400px;
            left: calc( 50% - 200px );
            top: calc( 50% - 200px - 50px );
            position: absolute;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 8px;
            .title {
                font-size: 24px;
                margin: 36px 0 0;
                color: #116644;
            }
            .sub-title {
                padding-bottom: 20px;
                margin: 0;
                font-size: 14px;
                /*color: #959893;*/
                color: #229922;
            }
            .checkcode-wrap {
                width: 106px;
                height: 35px;
                margin-bottom: 30px;
            }
            .btn-wrap {
                padding-top: 10px;
                .el-button--primary {
                    width: 100%;
                }
            }
        }
        .form-wrap {
            padding: 0 40px;
            .el-form-item {
                margin-bottom: 28px;
            }
            .prefix-wrap {
                padding: 2px 5px 0;
            }
        }
    }
</style>
