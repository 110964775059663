
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

/**
 * @param title 生成的文件名
 * @params 需要隐藏元素的id集合
 */
export default {
    install(Vue, options) {
        Vue.prototype.getPdf = function (title, ids) {
            // var title = this.htmlTitle  //DPF标题
            for (var i = 0; i < ids.length; i++) {
                document.querySelector('#' + ids[i]).style.display = 'none';
            }

            // 生成pdf不全解决方案
            window.pageYoffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            let target = document.querySelector('#pdfDom');

            html2Canvas(target, {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                //width:960,
                //height:5072,
                dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
                scale: 4 //按比例增加分辨率
            }).then(function (canvas) {

                var contentWidth = canvas.width
                var contentHeight = canvas.height
                // 一页pdf显示html页面生成的canvas高度;
                var pageHeight = contentWidth / 592.28 * 841.89
                // 未生成pdf的html页面高度
                var leftHeight = contentHeight
                // 页面偏移
                var position = 10
                var pageData = canvas.toDataURL('image/jpeg', 1.0)
                var PDF = new JsPDF('', 'pt', 'a4')
                //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                var a4Width = 595.28;
                var a4Height = 595.28/contentWidth * contentHeight;

                // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                // 当内容未超过pdf一页显示的范围，无需分页
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 10, 10, a4Width, a4Width)//添加图像到页面，保留10mm边距
                    // PDF.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight)//添加图像到页面，保留10mm边距
                } else {
                    while (leftHeight > 0) {
                        console.log("")
                        // PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        PDF.addImage(pageData, 'JPEG', 0, position, a4Width, a4Height)
                        leftHeight -= pageHeight
                        position -= 841.89
                        // position -= 595.28
                        // 避免添加空白页
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(title + '.pdf')
                console.log('你好，打印PDF')
            })
        }
    }
}
