import {getAction} from "@/api/manage";
import { setUpRouteUtil } from '@/utils/util';
export default {

    install (Vue,options) {
        //判断 快递订单状态
        Vue.prototype.$parseKdOrderStatus = function(val) {

            // 0 待收货
            // 1 待确认发票
            // 2 待发货
            // 3 已发货
            // 4 待锁单
            // 5 已完成
            // 6 问题件
            //7 已取消
            if (val === '0') {
                return '待收货';
            }else if (val === '1') {
                return '待确认发票';
            }else if (val === '2') {
                return '待发货';
            }else if (val === '3') {
                return '已发货';
            }else if (val === '4') {
                return '待锁单';
            }else if (val === '5') {
                return '已完成';
            }else if (val === '6') {
                return '问题件';
            }else if (val === '7') {
                return '已取消';
            }

        }
        //判断 扣货状态
        /**
         * 
         * @param {*} val 
         * @param {*} limitsOne val是1的是的显示权限， 1的时候显示暂存仓库，0的时候显示手动扣货
         * @returns 
         * 
         */
        Vue.prototype.$parseOrderDelivery = function(val, limitsOne) {
            // 将订单的 扣货状态 按照不同状态分为 0 正常
            // 1 手动扣货（触发条件 所有订单点击扣货）
            // 2 自动扣货（触发条件 定时任务单票派送前结算 触发的扣货）
            // 3 手动放货（所有订单点击放货）
            // 4 自动放货（新增收款申请 修改收款申请时触发的放货）
            // 5 无循环订单自动扣货 （定时任务无循环订单直接扣货触发的）
            // 6 无循环订单自动放货（下单的时候 触发的 无循环订单自动放货）
            if (val === '1') {
                return limitsOne && limitsOne == '1' ? '暂存仓库' : '手动扣货';
            }else if (val === '2') {
                return '自动扣货';
            }else if (val === '3') {
                return '手动放货';
            }else if (val === '4') {
                return '自动放货';
            }else if (val === '5') {
                return '无循环订单自动扣货';
            }else if (val === '6') {
                return '无循环订单自动放货';
            }

        }
        //进入 客户详情页面
        Vue.prototype.$intoCustomerEditPage = function(id) {
            let flag = this.$btnIsShow('isystem/RoleManage','0','可以跳转查看客户详情');
            console.log("有没有权限看客户",flag);
            // 对于路由不在左侧菜单中的选中菜单处理
            if (flag) {
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${id}`);
            }else {
                this.$message.warning('您没有权限查看客户详情');
            }

        }
        //进入柜子 详情页面
        //进入 客户详情页面
        Vue.prototype.$intoOperationCabinetEditPage = function(id) {
            let flag = this.$btnIsShow('isystem/RoleManage','0','客服页-点击周数跳转到柜子详情页');
            console.log("有没有权限",flag);
            // 对于路由不在左侧菜单中的选中菜单处理
            if(flag) {
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
            }else {
                this.$message.warning('您没有权限查看柜子详情');
            }
        }

        //判断角色类型 销售跳进销售的客服页
        Vue.prototype.$intoDocPage = async function(orderNo){
            let ss = localStorage.getItem("USERINFO");
            var user = JSON.parse(ss);
            if (orderNo && orderNo.indexOf("KD") !== -1) {
                console.log('是快递订单');
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                return;
            }else {
                getAction("/interface/ttOrder/getTypeByOrderNo",{orderNo:orderNo})
                    .then(res=>{
                        var type = res.result
                        let isSlaes = '0';
                        if ( (user.depStr && user.depStr.indexOf("销售")!==-1) || (user.roleName && user.roleName.indexOf("销售")!==-1 )){
                            //销售
                            isSlaes = '1';
                        }
                        // let flag = this.$btnIsShow('isystem/RoleManage','0','可以跳转查看海运订单详情');
                        // console.log("有没有权限看海运订单",flag);
                        // if (flag) {
                            if (type === 'LCL') {
                                // if (this.issalesman == "1") {
                                //         this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
                                //       } else {
                                //         // 对于路由不在左侧菜单中的选中菜单处理
                                //         setUpRouteUtil(this.$route.path, '/docpage/Edit');
                                //         this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                                //       }
                                // if (isSlaes==='1'){
                                //     //销售
                                //     setUpRouteUtil(this.$route.path, '/docpage/EditSale');
                                //     this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
                                // }else {
                                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

                                // }
                            } else if (type === 'FCL') {
                                // if (isSlaes==='1'){
                                //     //销售
                                //     setUpRouteUtil(this.$route.path, '/docpage/EditSaleFCL');
                                //     this.$router.push("/docpage/EditSaleFCL?orderNo=" + orderNo);
                                // }else {
                                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

                                // }
                            }else {
                                this.$message.warning("该订单不存在系统中");
                                return false;
                            }
                        // }else {
                        //     this.$message.warning('您没有权限查看海运订单详情');
                        // }

                    })
            }


        }
        //判断角色类型 销售跳进销售的客服页 -批量
        Vue.prototype.$intoDocPageBatch = async function(orderNoList){
            if(orderNoList && orderNoList.length > 10) {
                this.$message.warning('批量打开订单最多可支持勾选10个！');
                return;
            }
            orderNoList && orderNoList.length && orderNoList.forEach(item => {
                this.$intoDocPage(item);
            })
        }

        Vue.prototype.$initBtnPermission = async function(){
            // console.log('测试按钮权限',this.$route.path.substring(1))
            await getAction('/sys/button/auth/getPermission')
                .then(res => {
                    if (res.result) {
                        // console.log('res.result.length',Object.keys(res.result).length);
                        this.$root.btnObj = res.result;
                        localStorage.setItem('btnPermission', JSON.stringify(this.$root.btnObj));
                        // console.log('刷新按钮权限')
                    }

                })
        }


        Vue.prototype.$refreshBtnPermission = async function(){
            // console.log('测试按钮权限',this.$route.path.substring(1))
            await getAction('/sys/button/auth/getPermission',{path:this.$route.path.substring(1)})
                .then(res => {
                    if (res.result) {
                        // console.log('res.result.length',Object.keys(res.result).length);
                        // this.$root.btnObj = res.result;
                        // localStorage.setItem('btnPermission', JSON.stringify(this.$root.btnObj));
                        if (localStorage.getItem('btnPermission')){
                            let ss = JSON.parse(localStorage.getItem('btnPermission'))
                            for (var name in res.result){
                                ss[name] = res.result[name];
                            }
                            console.log('刷新按钮权限');
                            localStorage.setItem('btnPermission',JSON.stringify(ss));
                        }
                        // console.log('刷新按钮权限')
                    }

                })
        }

        Vue.prototype.$btnIsShow =  function (path,isTable,btnName) {
            // console.log("计算按钮权限",path,isTable,btnName)
            let flag = false;
            let btnObj = {};
            let ss =localStorage.getItem("USERINFO");
            if (ss){
                let user = JSON.parse(ss);
                if (user && (user.username==='admin' || user.username === 'sz.peter')){
                    return true;
                }
            }
            if (localStorage.getItem('btnPermission')){
                btnObj = JSON.parse(localStorage.getItem('btnPermission'));
            }else {
                btnObj = this.$root.btnObj;
            }
            if (btnObj && btnObj[path]){
                let list = btnObj[path];
                let qaq = [];
                qaq = list.filter((item) => {
                    return item.btnName === btnName && item.isShow === '1'
                });
                if (qaq && qaq.length > 0) {
                    flag = true;
                }else {
                    flag = false;
                }
                // list.map(l=>{
                //     // if (isTable === l.isTable){
                //         if (btnName === l.btnName){
                //             flag = l.isShow ==='1';
                //         }
                //     // }
                // })
            }else {
                flag =  false;
            }

            return flag;
        }
        Vue.prototype.$btnIsDisabled =  function (path,isTable,btnName) {
            let flag = true;
            let btnObj = {};
            if (localStorage.getItem('btnPermission')){
                btnObj = JSON.parse(localStorage.getItem('btnPermission'));
            }else {
                btnObj = this.$root.btnObj
            }
            if (btnObj && btnObj[path]){
                let list = btnObj[path];
                for (var i=0;i<list.length;i++){
                    let l = list[i];
                    if (isTable === l.isTable){
                        if (btnName === l.btnName){
                            flag = l.isDisabled ==='0';
                            break;
                        }
                    }
                }


            }else {
                flag =  true;
            }
            // console.log('isDisabled',flag);
            return flag;
        }

        Vue.prototype.getPlatform = function (){
            let plat = "pc";
            let p = navigator.platform;
            let u = navigator.userAgent;
            let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
                console.log('PC环境--供PC端调试');
                plat= "pc";
            }
            else {
                if (is_android) {//Android终端
                    console.log('Mobile环境--Android移动端');
                    plat= "android";
                } else if (is_ios) {//IOS终端
                    console.log('Mobile环境--IOS移动端');
                    plat= "ios";
                }
            }
            console.log("当前正在用的设备：",plat)
            return plat;
        }

        Vue.prototype.getPlatformEasy = function (){
            let plat = "pc";
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("android") !== -1) {
                plat = "android";
            }else if (ua.indexOf("iphone") !== -1){
                plat = "ios";
            }
            console.log("当前设备：",plat)
            return plat;
        }
        // 判断是否是 报关行登录
        Vue.prototype.$checkIsBaoguan = function (){
            let userInfoStr = localStorage.getItem('USERINFO');
            let flag = true;
            if (userInfoStr) {
                let userObj = JSON.parse(userInfoStr);
                if (userObj) {
                    flag = userObj['isDeclareAgent'] !== '1'
                }
            }
            return flag;
        }
        //上传文件之前
        Vue.prototype.$beforeUpload = function (file){
            let name = "";
            if (file && file.name) {
                name = file.name;
            }
            const isFile = name.indexOf('.') !== -1;
            if (!isFile) {
                this.$message.warning('该文件名不合法')
            }
            return  isFile;
        }
        // 判断是否是 报关行登录
        Vue.prototype.$checkIsBaoguan = function (){
            let userInfoStr = localStorage.getItem('USERINFO');
            let flag = true;
            if (userInfoStr) {
                let userObj = JSON.parse(userInfoStr);
                if (userObj) {
                    flag = userObj['isDeclareAgent'] !== '1'
                }
            }
            return flag;
        }

        //判断是否可以对柜子数据进行编辑
        Vue.prototype.$canEditCabinetInfo = function () {
            let flag = this.$btnIsShow('isystem/RoleManage','0','可以编辑柜子信息');

            return flag;
        }

        Vue.prototype.$getJasperUrl = function (){
            if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000" || process.env.VUE_APP_API_URL == "http://127.0.0.1:11000") {
                return this.GLOBAL.dictListMap.remote_download_jasper_url;
            } else {
               return this.GLOBAL.dictListMap.remote_test_download_jasper_url;
            }
        }
    }
}
