import BasicLayout from '@/components/Layout/BasicLayout'
import RouteView from '@/components/Layout/RouteView'
import da from "element-ui/src/locale/lang/da";

const generateData = (data) => {
    let routes = []
    for(let i = 0;i < data.length; i++) {
        let { id, path, title, component, icon, hidden, keepAlive, redirect, internalOrExternal, children } = data[i]
        if(component === 'BasicLayout') {
            component = BasicLayout
        } else if(component === 'RouteView') {
            component = RouteView
        } else {
            component = (resolve) => require([`@/views/${data[i]['component']}.vue`], resolve)
        }

        let route = {
            id,
            title,
            name: path,
            path,
            component,
            icon,
            hidden,
            _blank: internalOrExternal,
            meta: {
              title,
              path,
              keepAlive
            }
        }

        if(children && children.length) {
            route['children'] = generateData(data[i]['children'])
        }

        redirect && (route.redirect = redirect)
        routes.push(route)
    }
    return routes
}

const generateRoutes = (data) => {
    let routes = generateData(data)
    let redirect = {
        path: '*',
        redirect: '/'
    }
    let tempRoutes = []

    const fn = (data) => {
        for(let i = 0; i < data.length; i++) {
            if(data[i]['_blank'] && !data[i]['children']) {
                tempRoutes.push(...data.splice(i, 1))
            } else if(data[i]['children'] && data[i]['children'].length) {
                fn(data[i]['children'])
            }
        }
    }
    fn(routes)

    return [...routes.slice(1), ...tempRoutes, redirect]
}

const generateSibarData = (data) => {
    let routes = generateData(data)

    const fn = (data) => {
        for(let i = 0; i < data.length; i++) {
            if(data[i]['hidden'] && !data[i]['children']) {
                data.splice(i--, 1)
            } else if(data[i]['children'] && data[i]['children'].length) {
                fn(data[i]['children'])
            }
        }
    }
    fn(routes)

    return routes
}

const getUrlKey = function (name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

export {
    generateRoutes,
    generateSibarData,
    getUrlKey


}
