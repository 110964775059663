import { getAction, postAction } from './manage'
import axios from '@/utils/request'


export function addDeploymentByString(url, data, rest) {
    return axios({
        url: url,
        method: 'post',
        data: data,
        ...rest
    })
}

export function startProcess(url, data, rest) {

    return axios({
        url: url,
        method: 'get',
        data: data,
        ...rest
    })
}
