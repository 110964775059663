<template>
    <el-container>
        <el-aside :class="isCollapse ? 'collapsBox' : 'noCollapsBox'">
<!--            <div class="f-text-center logo-wrap">FBA</div>-->
            <div v-if="!isCollapse" class="flex_center">
              <img :src="imgSrc" height="40" alt=""/>
            </div>
            <el-scrollbar>
                <side-bar :isCollapse="isCollapse"></side-bar>
            </el-scrollbar>
        </el-aside>
        <el-container :class="isCollapse ? 'nopadding' : ''">
            <el-header>
                <header-nav :isCollapse="isCollapse" @toogle-collapse="handleToogleCollapse"></header-nav>
            </el-header>

            <el-main>
                <nav-tabs-bar class="nav-bar"></nav-tabs-bar>
                <!-- <div class="main-full-content"> -->
                    <keep-alive v-if="keepAlive">
                        <router-view></router-view>
                    </keep-alive>
                    <router-view v-else></router-view>
                <!-- </div> -->
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import HeaderNav from './HeaderNav.vue'
    import SideBar from './SideBar.vue'

    import NavTabsBar from './NavTabsBar'

    export default {
        name: 'BasicLayout',
        components: {
            HeaderNav,
            SideBar,
            NavTabsBar
        },
        data() {
            return {
                isCollapse: false,
                imgSrc:require('@assets/logo_back.png'),
            }
        },
        computed: {
            keepAlive () {
                return this.$route.meta.keepAlive
            }
        },
        methods: {
            handleToogleCollapse() {
                this.isCollapse = !this.isCollapse
            },
        },
    }
</script>

<style lang="scss" scoped>

    $base: #191a23;
    $baseOpen: #101117;
    $primary: #2d8cf0;

    body {
        #app >.el-container {
            height: 100%;
        }
        
    }
    ::v-deep {

        .el-container.is-vertical {
            padding-left: 150px;
            transition: all .3s ease-in-out;
            &.nopadding {
                padding-left: 64px;
                .nav-bar,
                .el-header {
                    left: 64px;
                }
            }
        }
        .el-main {
            padding: 0px 12px 12px !important;
            margin-top: 104px;
            overflow: inherit !important;
            // background: #f6f5fa;
            background-color: #FFFFFF;
            .main-full-content {
                position: relative;
                // padding: 10px;
                // min-height: 100%;
                box-sizing: border-box;
                background: #FFFFFF;
            }
        }
        .el-header {
            height: 40px;
            left: 150px;
            right: 0;
            z-index: 2000;
            position: fixed;
            background: #fff;
            padding-left: 0 !important;
            box-shadow: 0px 1px 10px #e6e6e6;
            transition: all .3s ease-in-out;
        }
        .collapsBox {
          width: 64px !important;
          .el-submenu__title {
            text-align: center;
          }
          .menutree, .el-menu {
            width: 64px !important;
          }
        }
        .noCollapsBox {
          width: 150px !important;
        }
        .el-aside {
            height: 100%;
            z-index: 1001;
            position: fixed;
            border-right: 1px solid #e6e6e6;
            box-shadow: 1px 0 10px #e6e6e6;
            overflow: hidden !important;
            background: $base;
            color: #ffffff;
            .logo-wrap {
                line-height: 60px;
            }
            .el-scrollbar {
                height: calc(100% - 40px);
                .el-scrollbar__wrap {
                    overflow-x: hidden !important;
                    box-sizing: border-box;
                    .el-menu {
                        border: none;
                        background: transparent;
                        .el-menu-item {
                            height: 46px;
                            line-height: 46px;
                            color: rgba(255,255,255,0.7);
                            &.is-active {
                                color: #ffffff !important;
                                background: $primary;
                                &:focus,
                                &:hover {
                                    background-color: $primary;
                                }
                            }
                            &:focus,
                            &:hover {
                                background-color: inherit;
                            }
                            &:hover {
                                color:#ffffff;
                            }
                        }
                        .el-menu-item-group {
                            background: $baseOpen;
                            .el-menu-item-group__title {
                                padding: 2px 0 2px 20px;
                            }
                        }
                        .el-menu--inline {
                            background: $baseOpen;
                        }
                        .el-submenu__title {
                            color: rgba(255,255,255,0.7);
                            &:hover {
                                background-color: inherit;
                            }
                            i {
                                color:rgba(255,255,255,0.7);
                            }
                        }
                        .el-submenu.is-active {
                            .el-submenu__title {
                                color: #ffffff;
                                i {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
            a.router-link-exact-active {
                color: #ffffff !important;
                background: $primary;
                &:focus,
                &:hover {
                    background-color: $primary;
                }
            }
        }
        .el-menu--collapse .menutree .el-menu-item .el-submenu__icon-arrow,
        .el-menu--collapse .menutree .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
            display: none;
        }
        .el-menu--collapse .menutree .el-menu-item span,
        .el-menu--collapse .menutree .el-submenu > .el-submenu__title span {
            display: none;
        }
        .el-menu--vertical {
            .el-menu {
                background: $baseOpen;
            }
            .el-menu-item-group__title {
                padding: 0;
            }
            .el-menu-item {
                height: 46px;
                line-height: 46px;
                color: rgba(255,255,255,0.7);
                &:hover {
                    background: inherit;
                }
                &.is-active {
                    color: #ffffff !important;
                    background: $primary;
                }
            }
        }
    }
</style>
