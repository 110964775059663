<template>
    <div class="menutree">
        <label v-for="menu in data" :key="menu.id">
            <el-submenu :index="menu.id" v-if="menu.children && menu.children.length > 0" :class="'menu_padding_level_' + (level + 1)">
                <template slot="title">
                    <i :class="menu.icon" v-if="menu.icon"></i>
                    <span v-if="menu.title == '待提柜'">{{menu.title}}</span>
                    <span v-else>{{menu.title}}</span>
                </template>
                <label>
                    <menutree :data="menu.children" :level="level + 1" v-if="menu.menu_type!=='2'"></menutree>
                </label>
            </el-submenu>
            <el-menu-item v-else-if="menu._blank" class="menu-blank-link-wrap f-text-ellipsis" :class="'menu_padding_level_' + (level + 1)">
                <router-link :to="menu.path" target="_blank" class="menu-blank-link" :style="{ paddingLeft: 5 * (level + 1) + 'px' }">
                    <i :class="menu.icon" v-if="menu.icon"></i>
                    <span>{{menu.title}}</span>
                </router-link>
            </el-menu-item>
            <el-menu-item class="f-text-ellipsis" :index="menu.path" v-else :class="'menu_padding_level_item_' + (level + 1)">
                <i :class="menu.icon" v-if="menu.icon"></i>
                <span slot="title" v-if="menu.title == '待提柜'">{{menu.title}}</span>
                <span slot="title" v-else>{{menu.title}}</span>
            </el-menu-item>
        </label>
    </div>
</template>

<script>
export default {
    name: 'menutree',
    props: ['data', 'level'],
    data() {
        return {

        }
    },
    created() {
      // console.log(this.data,'菜单渲染')
    },
}
</script>

<style lang="scss" scoped>
.menu-blank-link-wrap {
    position: relative;
    .menu-blank-link {
        width: 100%;
        height: 100%;
        padding-right: 45px;
        left: 0;
        position: absolute;
        box-sizing: border-box;
        text-decoration: none;
        color: inherit;
    }
}
.fa {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 15px;
}
</style>
<style lang="scss">
// 第一层级有下一个层级的样式
.menu_padding_level_1 {
  .el-submenu__title {
    padding-left: 5px !important;
  }
}
// 第一层级没有下一个层级的样式
.menu_padding_level_item_1{
  padding-left: 5px !important;
}
// 第二层级没有下一个层级的样式
.menu_padding_level_item_2 {
  padding-left: 30px !important;
}
// 第二层级有下一个层级的样式
.menu_padding_level_2 {
  .el-submenu__title, .menu-blank-link {
    padding-left: 30px !important;
  }
  
}
// 第三层级没有下一个层级的样式
.menu_padding_level_item_3 {
  padding-left: 40px !important;
}

</style>
