<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="字典名称">
                            <el-input v-model="queryForm.dictName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="字典编号">
                            <el-input v-model="queryForm.dictCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label="是否启用sql">
                        <f-select v-model="queryForm.activeSql" :is-need="queryForm.activeSql" :dict="'unit_whether'" @keyup.enter.native="handleSearch"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch"

                            >查询</el-button>
                            <el-button plain @click="handleReset"

                            >重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleEdit({})"

                           v-if="buttonShowList.add"

                >新增
                </el-button>
                <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleBatDel"
                            :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.plsc">批量删除
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    border
                    ref="GTable"
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'activeSql'">
                        <span v-if="scope.row.activeSql === '0'">不启用</span>
                        <span class="dot-badge" v-else>启用</span>
                    </template>
                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj">编辑</el-button>
                        <el-button type="text" @click="handleConfigure(scope.row)"
                                   v-if="buttonShowList.pz">配置</el-button>
                        <el-button type="text" @click="handleDel(scope.row)"
                                   v-if="buttonShowList.sc">删除</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <dict-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></dict-dialog>
        <dict-configure-dialog :visible.sync="configureVisible" :row="row" @ok="handleSearch"></dict-configure-dialog>
    </div>
</template>

<script>
    import {deleteAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"
    import DictDialog from './modules/DictDialog'
    import DictConfigureDialog from './modules/DictConfigureDialog'

    export default {
        name: 'Tempcclate',
        mixins: [QueryMixins],
        components: {
            DictDialog,
            DictConfigureDialog
        },
        data() {
            return {
                row: {},
                visible: false,
                configureVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        label: '#',
                        type: 'index',
                        width: 50
                    },
                    {
                        prop: 'dictName',
                        label: '字典名称',
                        sortable: 'custom',
                    },
                    {
                        prop: 'dictCode',
                        label: '字典编码',
                        sortable: 'custom',
                    },
                    {
                        prop: 'activeSql',
                        label: '是否启用sql',
                        show: false,
                    },
                    {
                        prop: 'description',
                        label: '描述',
                        show: false,
                        minWidth: 240,
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 200,
                    },
                ],
                url: {
                    list: '/sys/dict/list',
                    delete: '/sys/dict/delete',
                    deleteBatch: '/sys/dict/deleteBatch',
                }
            }
        },
        created() {
            this.$initBtnPermission();
        },
        methods: {
            handleEdit(row) {
                this.row = row
                this.visible = true
            },
            handleConfigure(row) {
                this.row = row;
                console.log('小彪子缓存', this.row);
                this.configureVisible = true
            },
            handleDel({id, dictName}) {
                this.$confirm(`确定将【${dictName}】删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleBatDel() {
                this.$confirm(`确定批量删除共${this.selectedRowKeys.length}项？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('isystem/Dict','0','新增'),
            'plsc':this.$btnIsShow('isystem/Dict','0','批量删除'),
            'bj':this.$btnIsShow('isystem/Dict','1','编辑'),
            'pz':this.$btnIsShow('isystem/Dict','1','配置'),
            'sc':this.$btnIsShow('isystem/Dict','1','删除'),
          }
        }
      }
    }
</script>
