<template>
  <div>
    <el-dialog class="yulan" title="预览" append-to-body :visible="visible" custom-class="dialogClass" top="50px" width="50%"
      :before-close="() => { $emit('setImgVisible', false) }">
      <!--图片-->
      <viewer v-if="['jpg','jpeg','img','gif','png','pneg'].indexOf(type) !== -1" :images="[imgUrl]" ref="viewer" :options="optionsViewer">
        <img width="100%" v-for="pic in [imgUrl]" :src="pic" :key="pic" />
      </viewer>
      <!--doc，excel-->
      <div v-else-if="['doc','docx','xlsx','xls'].indexOf(type) !== -1">
        <iframe class="child" frameborder="0" :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.imgUrl">
        </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    // 文件类型
    type: {
      type: String,
      default: 'img',
    }
  },
  data() {
    return {
      optionsViewer: {
        zIndex: 99999,
        inline: false,
        navbar: false,
        scalable: false,
        title: false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          reset: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        }
      }
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.child {
  width: 100%;
  height: 75vh;
}
</style>