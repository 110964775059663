
import {httpAction, postAction} from "@/api/manage";
import axios from "axios";

export default {
    data() {
        return {
            url:{
                uploadDataSave: '/sys/oss/file/uploadDataSave',
                addFile: '/interface/ttOrder/addOneFile',
                saveBatchFile: '/order/kdOrder/saveBatchFile',
                addOneOsOrderFileByOSSFile:'/interface/ttOrder/addOneOsOrderFileByOSSFile',
                addOneCusFile:'/customer/file/add',
                policy: '/customer/oss/file/policy',
            },
            ossClientFileList:[],
            ossClient: null,
        }
    },

    created: function () {

    },
    mounted() {

    },
    computed: {
    },
    methods: {

        /**  阿里云OSS上传 */
       async  kdHandleUploadOss(request,fileObj,uuid,orderNos,plate,dir) {
           const file =request.file;
           const that = this;
           const fileItem = await this.handleUploadOss2(request,plate,dir,uuid);
           console.log("上传返回的结果：", fileItem);
            if (!fileItem) {
                that.$message.warning('上传文件失败');
                return false;
            }
            this.ossClientFileList.push(fileItem);
            let {id, size, name, url} = fileItem;
            let formData = new FormData();
            formData.append('url', url);
            formData.append('ossId', id);
            formData.append('size', size);
            formData.append('name', name);
            formData.append('fileName', name);
            formData.append('type', "44");
            formData.append('file', fileObj);
            this.$store.commit("setFullscreenLoading", true);
            postAction(this.url.saveBatchFile,  formData)
                .then(res => {
                    if (res.success) {
                        this.$store.commit("setFullscreenLoading", false);
                        this.$message.success(res.message);
                        this.handleQuery();
                    }
                }).catch(() => {
                this.$store.commit("setFullscreenLoading", false);
            })
        },

        async handleUploadCusFile(request,cusId,plate,dir){
           const that = this;
           let file = request.file;
           let fileItem = await this.handleUploadOss2(request,plate,dir);
           console.log("上传返回的结果：", fileItem);
           if (!fileItem) {
               that.$message.warning('上传文件失败');
               return false;
           }
            that.$message.success('上传成功');
            that.fileList.push(fileItem);

            if (cusId) {
                httpAction(that.url.addOneCusFile,'post',
                    {
                        url:fileItem.url,
                        ossId:fileItem.id,
                        size:file.size/1000,
                        fileType:that.queryForm.fileType,
                        name:file.name,
                        cusId:cusId
                    }
                ).then(res => {
                    that.dialogFormVisible = false;
                    that.$message.success(res.message);
                    that.handleQuery();
                })
            }
        },
        async handleUploadOss(request,uuid,orderNo,needSyncOsOrderFile,plate,dir) {
            const that = this;
            let fileItem = await this.handleUploadOss2(request,plate,dir);
            console.log("上传返回的结果：" , fileItem);
            if (!fileItem) {
                that.$message.warning('上传文件失败');
                return false;
            }
            if (orderNo) {
                let {id, size, ext, name, url} = fileItem;
                this.$set(this.form,'url',url);
                this.$set(this.form,'ossId',id);
                this.$set(this.form,'size',size);
                this.$set(this.form,'type',ext);
                this.$set(this.form,'name',name);
                this.$set(this.form,'fileName',name);
                this.$set(this.form,'type','15');
                this.$set(this.form,'orderNo',orderNo);
                httpAction(this.url.addFile, 'post', this.form)
                    .then(res => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            }
            if (needSyncOsOrderFile === '1') {
                postAction(this.url.addOneOsOrderFileByOSSFile,
                    {
                        id:fileItem.id,
                        name:fileItem.name,
                        url:fileItem.url,
                        size:fileItem.size
                    }).then(res=>{
                    this.$message.success(res.message);
                })
            }

        },
        // 获取uuid
        generateUUID(length) {
            var d = new Date().getTime();
            let uuid = d.toString() + Math.random().toString().substr(3, length);
            return uuid;
        },

        async handleUploadOss2(request,plate,dir,uuid,needSaveOssFile) {
            if (!needSaveOssFile) {
                needSaveOssFile = "1";
            }
            const file = request.file;
            console.log(file, "阿里云OSS文件上传新版本");
            if (!uuid) {
                uuid = this.generateUUID(6);
            }
            file.status = "uploading";
            const formData = new FormData();
            let fileName = file.name.substring(0, file.name.lastIndexOf(".")) + file.uid + "." + file.name.substring(file.name.lastIndexOf(".") + 1);
            this.$store.commit("setFullscreenLoading", true);
            const that = this;
            let fileItem = {};
            //获取oss上传令牌
            try {
                const policyResponse = await postAction(this.url.policy + `?dir=${dir}`,
                    { path: (process.env.VUE_APP_ENV !== 'production' ? 'test/' : '') + plate, fileList: [fileName] });
                const fileData = policyResponse.result;
                console.log("权限认证结果",fileData);
                fileItem = {
                    id: uuid,
                    uid: file.uid,
                    name: file.name,
                    url: fileData.url,
                    fileSize: file.size,
                    type: file.name.substring(file.name.lastIndexOf(".") + 1),
                };
                formData.append("OSSAccessKeyId", fileData.accessid);
                formData.append("policy", fileData.policy);
                formData.append("Signature", fileData.signature);
                formData.append("dir", fileData.dir);
                formData.append("host", fileData.host);
                formData.append("key", fileData.dir + fileName);
                formData.append("success_action_status", "200");
                formData.append("file", file);
                console.log("准备执行上传文件到阿里云",fileItem,fileData);
                const uploadResponse = await axios({
                    url: fileData.host,
                    method: "POST",
                    data: formData,
                    processData: false,
                    cache: false,
                    contentType: false,
                    headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundarybr4J0lADpEKKdS8H" },
                    onUploadProgress: function (progressEvent) {
                        console.log(progressEvent, "上传进度变量");
                    },
                });
                console.log("上传成功",uploadResponse);
                // 保存上传的文件信息
                if (needSaveOssFile === '1') {
                    const saveResponse = await postAction(this.url.uploadDataSave, fileItem);
                }
                this.$store.commit("setFullscreenLoading", false);
                return fileItem;
            }catch (error) {
                console.log("认证失败",error)
                this.$store.commit("setFullscreenLoading", false);
                this.$message.warning("上传文件失败");
            }
        }
    },
}
