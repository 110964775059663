import axios from '@/utils/request'


export function httpAction(url, method, data,rest) {
    return axios({
        // url: url,
        url: url,
        method: method,
        timeout:10 *60 * 1000,
        data: data,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        ...rest
    })
}

export function httpActionWithParams(url, method, data,rest) {
    if (method.toLowerCase() === 'get'){
        console.log("get请求")
        return axios({
            // url: url,
            url: url,
            method: method,
            timeout:10 *60 * 1000,
            params: data,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            ...rest
        })
    }else {
        console.log("post请求")
        return axios({
            // url: url,
            url: url,
            method: method,
            timeout:10 *60 * 1000,
            data: data,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            ...rest
        })
    }

}


export function getAction(url, params, rest) {
    return axios({
        // url: url,
        url: url,
        method: 'get',
        params: params,
        timeout:10 * 60 * 1000,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        ...rest
    })
}

export function postAction(url, data, rest) {
    return axios({
        url: url,
        method: 'post',
        timeout:10 * 60 * 1000,
        data: data,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        ...rest
    })
}

/**重新设置超时时间的请求*/
export function postRefreshAction(url, data, rest) {
    return axios({
        url: url,
        method: 'post',
        timeout:10 * 60 * 1000,
        data: data,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        ...rest
    })
}

export function postActionApi(url, data, rest) {
    return axios({
        url: url,

        method: 'post',
        data: data,
        timeout:10 * 60 * 1000,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        ...rest
    })
}

export function putAction(url, data) {
    return axios({
        url: url,
        method: 'put',
        timeout:10 * 60 * 1000,
        data: data,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
    })
}

export function deleteAction(url, params, data) {
    console.log(params)
    return axios({
        url: url,
        method: 'delete',
        params: params,
        timeout:10 * 60 * 1000,
        data: data,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
    })
}
//获取所有用户
export function getallusers(){
    return axios({
        url : '/sys/user/list',
        params:{pageSize:1000},
        method : 'get',

    })
}
//获取业务员
export function getallsalesmans(){

    return axios({
        url : '/sys/user/salesman/list',
        method : 'get',
    })
}
//根据业务员获取关联的客服、账单人
export function getallrealtion(params){
    return axios({
        url:'/customer/relation/getRelationById',
        method : 'get',
        params : params
    })
}
//查询国家基础数据表
export function getallcountries(){
    return axios({
        url:'/sys/bdCountry/getCountries',
        method:'get'
    })
}
//根据国家获取省
export function getstateList(params){
    return axios({
        url:'/sys/bdCountry/getStateList',
        method:'get',
        params:params
    })
}

//根据省获取市
export function getcityList(params){
    return axios({
        url:'/sys/bdCountry/getCityList',
        method:'get',
        params:params
    })
}
//获取系统币种
export function getcurlist(){
    return axios({
        url:'/sys/cur/list',
        method:'get',
    })
}
//获取国家级联数据 暂时不用
export function getCasedCountry(){
    return axios({
        url:'/sys/bdCountry/getCased',
        method : 'get',
    })
}


//获取所有港口基础数据
export function getBdPortList() {
    return axios({
        url: '/sys/bdPort/list',
        method: 'get',
    })

}
//获取所有费用类别(1:海运费用类别，2：快递费用类别)
export function getBdFeeTypeList(type) {
    return axios({
        url: '/sys/feeType/list/'+type,
        method: 'get',
    })
}

//获取所有报价说明类型
export function getPriceExplainList() {
    return axios({
        url: '/sys/priceExplain/allList',
        method: 'get',
    })
}

//获取所有供应商传null，其他传对应值，如要获取海运＋快递供应商传 1,2
export function getAllSupplier(params) {
    return axios({
        url: '/sys/supplier/allList',
        method: 'get',
        params: {params: params}
    })
}
//获取所有文章类别
export function getAllList(params) {
    return axios({
        url: '/sys/managerType/getAllList',
        method: 'get',
        params:params
    })
}

//获取所有文章类别
export function getAllArticle(params) {
    return axios({
        url: '/sys/managerType/allList',
        method: 'get',
        params:params
    })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url,parameter){
    return axios({
        url: url,
        params: parameter,
        method:'get' ,
        responseType: 'blob'
    })
}

export function postBlobAction(url,parameter) {
    return axios({
        url: url,
        method:'post' ,
        data: parameter,
        responseType: 'blob'
    })
}

/**
 *文件下载
 * param {'orderNo':'c','orderNo':'b'}
 * @param avatar 文件访问地址
 */
export function getFileHttpUrl(avatar,params) {
    let {keys, values, entries} = Object;
    let param=''
    let num=0
    for (let [key, value] of entries(params)) {

        let obj=[key, value]
        if (num===0){
            param=param+'?'+obj[0]+'='+obj[1]
        }else {
            param=param+'%26'+obj[0]+'='+obj[1]
        }
        num++
    }
    const token = Vue.ls.get(ACCESS_TOKEN)
    let url= window._CONFIG['domianURL']+'/sys/forwardNewPagge?url='+avatar+param+'&token='+token;
    return url
}
// {
//   url: url,
//   method:'post' ,
//   data: parameter,
// }
export function allAction(allInterface) {
  return axios.all(allInterface)
}
export function DeleteActionByUrlParam(url, params, data) {
    console.log(params)
    return axios({
        url: url + params,
        method: 'delete',
        timeout:60 * 1000,
        data: data,
    })
}
export function GetActionByUrlParam(url, params, rest) {
    return axios({
        // url: url,
        url: url +'/'+ params,
        method: 'get',
        timeout:10 * 60 * 1000,
        ...rest
    })
}
