import Axios from "axios";
import store from "@/store";
import router from '@/router';
import { Message } from "element-ui";


// 创建axios实例
const axios = Axios.create({
  // baseURL: 'http://8.135.121.105:9000', // wiki
  // baseURL: 'http://192.168.1.87:9001', // max
  // baseURL: 'http://192.168.1.87:9005', // max
  // baseURL: 'http://127.0.0.1:9600/api', // peter
  // withCredentials: true, // 跨域时若要发送cookies需设置该选项
  baseURL: '/api',
  timeout: 3000000, // 超时5分钟
  withCredentials: false, // 允许携带cookie
});


const CancelToken = Axios.CancelToken
var cancelHash = {};
const needCancelUrls =
    [
        '/express/test/getAmazonPushLogIPage',
        '/operation/container/WaitForHandOutList',
        '/operation/container/waitLockList',
        '/order/channel/price/pageListPost'
    ];
const getLoginUserId = function () {
    let userId = '';
    let userInfoStr = localStorage.getItem('USERINFO');
    if (userInfoStr) {
        let user = JSON.parse(userInfoStr);
        if (user) {
            userId = user['id'];
        }
    }
    return userId;
}
// 请求拦截
axios.interceptors.request.use(
  config => {
    console.log("reqeust",config);
    //文件下载的请求
    if (config
        && config['responseType']
        && config['responseType'] === 'blob'
        && config['url']
        && config['url'].indexOf("warehouse/arrive/packageUpload") === -1
        ||  (config['url'].indexOf('sys/dict') == -1
            && config['url'].indexOf("order/os/file/saveFileListWithContainerId") === -1
            && (config['url'].indexOf('import') !== -1
        || config['url'].indexOf('push') !== -1
        || config['url'].indexOf('download') !== -1
        || config['url'].indexOf('set') !== -1
        || config['url'].indexOf('add') !== -1
        || config['url'].indexOf('update') !== -1
        || config['url'].indexOf('upload') !== -1
        || config['url'].indexOf('check') !== -1
        || config['url'].indexOf('del') !== -1
        || config['url'].indexOf('caculate') !== -1
        || config['url'].indexOf('send') !== -1
        || config['url'].indexOf('create') !== -1
        || config['url'].indexOf('order/os/file/invoiceCode') !== -1))
    ) {
        console.log("开启加载框",config['url']);
      store.commit("setFullscreenLoading", true);
    }
    const token = localStorage.getItem('TOKEN')
    if (token) {
      config.headers['X-Access-Token'] = token;
    }
    // 外网ip&设备id&内网ip
    if (localStorage.getItem('internet_ip') || localStorage.getItem('browser_id') || localStorage.getItem('intranet_id')) {
      config.headers['device_id'] = localStorage.getItem('internet_ip') + '&' + localStorage.getItem('browser_id') + '&' + localStorage.getItem('intranet_id');
    }
    //获取当前用户id
    let userId = getLoginUserId();

    if(needCancelUrls.indexOf(config.url) !== -1) {
      console.log("准备取消的请求",config.url,cancelHash);
      let cancelKey = config.url;
      if(cancelHash[cancelKey]) {
        const source = CancelToken.source()
        config.cancelToken = source.token
        // source.cancel('重复请求')
        cancelHash[cancelKey](`取消上次${cancelKey}未完成请求`);
        // console.log("取消上次未完成的请求",cancelKey);
        //删除对应的key
      } else {
        cancelHash[cancelKey] && cancelHash[cancelKey](`取消上次${cancelKey}未完成请求`)
        config.cancelToken = new CancelToken(function executor(c) {
          cancelHash[cancelKey] = c;
        })
      }
    }
    console.log(cancelHash, 'cancelHash')
    return config;
  },
  error => {
    // 请求错误预处理
    //console.log(error) // for debug
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  // 通过自定义code判定响应状态，也可以通过HTTP状态码判定
  response => {

    response && response.config && response.config.url && delete cancelHash[response.config.url + '-' + getLoginUserId()];
    // 仅返回数据部分
    let config = response.config;
    console.log("响应返回",config);
      if (config
          && config['responseType']
          && config['responseType'] === 'blob'
          && config['url']
          && config['url'].indexOf("warehouse/arrive/packageUpload") === -1

          ||  (config['url'].indexOf('sys/dict') == -1
              && config['url'].indexOf("order/os/file/saveFileListWithContainerId") === -1
              && (config['url'].indexOf('import') !== -1
              || config['url'].indexOf('push') !== -1
              || config['url'].indexOf('download') !== -1
              || config['url'].indexOf('set') !== -1
              || config['url'].indexOf('add') !== -1
              || config['url'].indexOf('update') !== -1
              || config['url'].indexOf('upload') !== -1
              || config['url'].indexOf('check') !== -1
              || config['url'].indexOf('del') !== -1
              || config['url'].indexOf('caculate') !== -1
              || config['url'].indexOf('send') !== -1
              || config['url'].indexOf('create') !== -1
              || config['url'].indexOf('order/os/file/invoiceCode') !== -1))
      ) {
          console.log("关闭加载框",config['url']);
      store.commit("setFullscreenLoading", false);
    }
    //如果是文件类型 就 做一层拦截
    if (config && config['responseType'] === 'blob') {
      const xiangying = response.data;
      if (xiangying) {
        if (xiangying && xiangying.type === 'application/json') {
          const reader = new FileReader()
          reader.onload = function () {
            const message = JSON.parse(reader.result).message
            Message({
              message: message,
              type: "warning",
              duration: 5 * 1000
            });
            return Promise.reject(new Error(message))
          }
          reader.readAsText(xiangying);

        }
      }
    }
    const res = response.data;
    if (res.success === false) {
      // 401-token为空; 402-token非法无效; 403-用户不存在; 404-账号已被锁定; 405-Token失效
      if (res.code === 401 || res.code === 402 || res.code === 403  || res.code === 11001) {
          localStorage.setItem("登录状态异常",JSON.stringify(res));
        // 重新登录
        // let msg = '登录状态异常，请重新登录'
        // if(res.code === 404) {
        //     msg = '账号已被锁定，请联系管理员'
        //     MessageBox.confirm(
        //       msg,
        //       "确认登录信息",
        //       {
        //         confirmButtonText: "重新登录",
        //         cancelButtonText: "取消",
        //         type: "warning"
        //       }
        //     ).then(() => {
        //         store.dispatch("user/resetToken").then(() => {
        //             router.push({
        //                 name: 'Login',
        //                 query: {
        //                     redirect: router.currentRoute.fullPath
        //                 }
        //             })
        //             location.reload()
        //         })
        //     })
        // } else {
        store.dispatch("user/resetToken").then(() => {
          router.push({
            name: 'Login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          location.reload()
        })
        // }
      } else {
        // 公共异常处理暂时去除，每个页面自己单独做异常弹窗处理
        if (response.config.showError && res.code == 413 || response.config['url'].indexOf("checkCanSee") !== -1
            || response.config['url'].indexOf('order/os/file/selectFileContainerId') !== -1
            || response.config.ClosePrompt) { } else {
          // showError传递为true，不弹err的弹窗
          Message({
            message: res.message || "Error",
            type: "error",
            duration: 5 * 1000
          })
        }
      }
      return Promise.reject(new Error(res.message || "Error"))
    } else {
      return res
    }
  },
  error => {
    store.commit("setFullscreenLoading", false);
    console.log("response", error.response)
    if (error.response) {
      let config = error.response.config;
      if (config && config['responseType'] === 'blob') {
        Message({
          message: "系统服务正在升级，请稍后重试！",
          type: "warning",
          duration: 5 * 1000
        });
      }
      // consoloe.log("response.status",error.response.status)
    }


    if (error && error.response && error.response.data && error.response.data === '服务异常') {
      Message({
        message: "系统服务正在升级，请稍后重试！",
        type: "warning",
        duration: 5 * 1000
      });
    }

    return Promise.reject(error)
  }
);

export default axios
